import React, { memo, useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';  // 导入 isMobile 检测

import { useStore } from '@/store'
import { chainListData } from '@/constants/pairsData'

import { request } from '@/services/axios'
import { formatStringWithEllipsis } from '@/utils/stringUtils'
import { formatNumberUSDBySize, multiply } from '@/utils/numberUtils'

import XlionXLogo_cut_up_down from '@/assets/Img/Logo/XlionXLogo_cut_up_down.png'
import logo_small from '@/assets/Img/Logo/logo_small.png'

import {
  PageWrap,
  PageMain,

  PagePCHeaderWrap,
  PagePCHeaderLeft,
  PagePCHeaderLeftLogo,
  PagePCHeaderLeftLogoImg,
  // PagePCHeaderLeftList,
  // PagePCHeaderLeftListItem,

  PagePCHeaderMiddle,
  PagePCHeaderMiddleContent,
  PagePCHeaderMiddleInputWrap,
  PagePCHeaderMiddleInputSearchIconWrap,
  PagePCHeaderMiddleInputSearchIcon,
  PagePCHeaderMiddleInputContent,
  // PagePCHeaderMiddleInputTip,
  // PagePCHeaderMiddleInputTipContent,

  PagePCHeaderRight,
  PagePCHeaderRightChainSelectWrap,
  PagePCHeaderRightChainSelectContainer,
  PagePCHeaderRightChainSelectContent,
  PagePCHeaderRightChainSelectLeft,
  PagePCHeaderRightChainSelectLeftImg,
  PagePCHeaderRightChainSelectIconWrap,
  PagePCHeaderRightChainSelectKeyboardArrowDownIcon,

  PagePCHeaderRightChainSelectPopupWrap,
  PagePCHeaderRightChainSelectPopupList,
  PagePCHeaderRightChainSelectPopupListItem,
  PagePCHeaderRightChainSelectPopupListItemImg,

  PagePCHeaderRightSettingBtn,
  PagePCHeaderRightSettingBtnContent,
  PagePCHeaderRightSettingBtnIconWrap,
  PagePCHeaderRightSettingsOutlinedIcon,

  PagePCHeaderRightSettingBtnPopupList,
  PagePCHeaderRightSettingBtnPopupListItem,
  PagePCHeaderRightSettingBtnPopupListItemContent,
  // PagePCHeaderRightSettingBtnPopupNotifyIconWrap,
  // PagePCHeaderRightSettingBtnPopupNotifyKeyboardArrowDownIcon,
  PagePCHeaderRightSettingBtnPopupThemeSwitch,

  PagePCHeaderRightConnectStatusWrap,
  PagePCHeaderRightConnectStatusContainer,
  PagePCHeaderRightLinkWalletConectContent,
  PagePCHeaderRightDisconnectContent,
  PagePCHeaderRightConnectContent,
  PagePCHeaderRightConnectBtn,

  PagePCHeaderRightCongregateContent,
  PagePCHeaderRightCongregateTgWrap,
  PagePCHeaderRightCongregateTgIconWrap,
  PagePCHeaderRightCongregateTelegramIcon,
  PagePCHeaderRightCongregateWalletInfoWrap,
  PagePCHeaderRightCongregateWalletBalanceWrap,
  PagePCHeaderRightCongregateWalletBalanceDehazeIcon,
  PagePCHeaderRightCongregateWalletBalanceNum,
  PagePCHeaderRightCongregateWalletAddressWrap,
  PagePCHeaderRightCongregateWalletAddressContent,
  PagePCHeaderRightCongregateWalletAddressContentContentCopyIcon,
  PagePCHeaderRightCongregateMoreWrap,
  PagePCHeaderRightCongregateMoreIconWrap,
  PagePCHeaderRightCongregateMoreKeyboardArrowDownIcon,

  PagePCHeaderRightCongregateMorePopupWrap,
  PagePCHeaderRightCongregateMorePopupList,
  PagePCHeaderRightCongregateMorePopupListLink,
  PagePCHeaderRightCongregateMorePopupListItem,


  PageMobileHeaderWrap,
  PageMobileHeaderContainer,
  PageMobileHeaderContent,
  PageMobileHeaderHomeLink,
  PageMobileHeaderHomeLinkLogo,
  PageMobileHeaderMainBody,

  // PageMobileHeaderMainChain,
  PageMobileHeaderMainChainSelectWrap,
  PageMobileHeaderMainChainSelectContainer,
  PageMobileHeaderMainChainSelectContent,
  PageMobileHeaderMainChainSelectLeft,
  PageMobileHeaderMainChainSelectLeftImg,
  PageMobileHeaderMainChainSelectIconWrap,
  PageMobileHeaderMainChainSelectKeyboardArrowDownIcon,

  PageMobileHeaderMainChainSelectPopupWrap,
  PageMobileHeaderMainChainSelectPopupList,
  PageMobileHeaderMainChainSelectPopupListItem,
  PageMobileHeaderMainChainSelectPopupListItemImg,

  PageMobileHeaderMainSearchIconWrap,
  PageMobileHeaderMainSearchIcon,

  PageMobileHeaderMainSettingBtn,
  PageMobileHeaderMainSettingBtnContent,
  PageMobileHeaderMainSettingBtnIconWrap,
  PageMobileHeaderMainSettingsOutlinedIcon,
  PageMobileHeaderMainSettingBtnPopupList,
  PageMobileHeaderMainSettingBtnPopupListItem,
  PageMobileHeaderMainSettingBtnPopupListItemContent,
  // PageMobileHeaderMainSettingBtnPopupNotifyIconWrap,
  // PageMobileHeaderMainSettingBtnPopupNotifyKeyboardArrowDownIcon,
  PageMobileHeaderMainSettingBtnPopupThemeSwitch,

  PageMobileHeaderMainConnectStatusWrap,
  PageMobileHeaderMainConnectStatusContainer,
  PageMobileHeaderMainLinkWalletConectContent,
  PageMobileHeaderMainDisconnectContent,
  PageMobileHeaderMainConnectContent,
  PageMobileHeaderMainConnectBtn,

  PageMobileHeaderMainCongregateContent,
  PageMobileHeaderMainCongregateTgWrap,
  PageMobileHeaderMainCongregateTgIconWrap,
  PageMobileHeaderMainCongregateTelegramIcon,
  PageMobileHeaderMainCongregateWalletInfoWrap,
  PageMobileHeaderMainCongregateWalletBalanceWrap,
  PageMobileHeaderMainCongregateWalletBalanceDehazeIcon,
  PageMobileHeaderMainCongregateWalletBalanceNum,
  PageMobileHeaderMainCongregateMoreWrap,
  PageMobileHeaderMainCongregateMoreIconWrap,
  PageMobileHeaderMainCongregateMoreKeyboardArrowDownIcon,
  PageMobileHeaderMainCongregateMorePopupWrap,
  PageMobileHeaderMainCongregateMorePopupList,
  PageMobileHeaderMainCongregateMorePopupListLink,
  PageMobileHeaderMainCongregateMorePopupListItem,
  
  PageBodyWrap,
} from './style'




const HomeTopMenuPage = observer(({isDarkMode, changeThemeClick, handleSearchModalOpen, handleTgLoginModalOpen}) => {

  // 获取状态管理库
  const { homeStore } = useStore()

  const location = useLocation();  // 获取当前路径
  const navigate = useNavigate();  // 用于跳转

  
  // 使用 useState 管理是否满足媒体查询条件的状态
  // const [isWideScreen, setIsWideScreen] = useState(false);

  // // 使用 useEffect 来设置媒体查询监听器
  // useEffect(() => {
  //   // 创建一个媒体查询，匹配宽度大于等于 62em 的情况
  //   const mediaQuery = window.matchMedia('(min-width: 62em)');

  //   // 定义当媒体查询条件变化时的处理函数
  //   const handleMediaQueryChange = (event) => {
  //     setIsWideScreen(event.matches);
  //   };

  //   // 添加监听器，监听媒体查询的变化
  //   mediaQuery.addEventListener('change', handleMediaQueryChange);

  //   // 初始化时检查媒体查询的状态
  //   handleMediaQueryChange(mediaQuery);

  //   // 清理监听器
  //   return () => {
  //     mediaQuery.removeEventListener('change', handleMediaQueryChange);
  //   };
  // }, []);

  
  /* 链选择弹窗 */
  const [chainSelectedAnchorEl, setChainSelectedAnchorEl] = useState(null);
  const chainSelectedOpen = Boolean(chainSelectedAnchorEl);
  const handleChainSelectedClickListItem = (event) => {
    setChainSelectedAnchorEl(event.currentTarget);
  };

  const handleChainSelectedClose = () => {
    setChainSelectedAnchorEl(null);
  };

  
  const contentBoxListItemClick = async (item) => {
    // console.log('contentBoxListItemClick', item)
    try {
      const token = homeStore.tgUserToken
      if (token) {
        const res = await request('lionx/user/bonus/info', {
          token: homeStore.tgUserToken,
          chainName: item.requestParams
        })
        const { data } = res
  
        if (data) {
          homeStore.handleChangeTgUserInfoData(data)
  
          handleChainSelectedClose()
  
          homeStore.changeChainSelect(item)
          // 目标路径
          const targetPath = '/';
  
          // 判断当前路径是否是目标路径
          if (location.pathname !== targetPath) {
            // 如果不是目标路径，则跳转
            navigate(targetPath);
          }
        }
      } else {
        handleChainSelectedClose()

        homeStore.changeChainSelect(item)
        // 目标路径
        const targetPath = '/';

        // 判断当前路径是否是目标路径
        if (location.pathname !== targetPath) {
          // 如果不是目标路径，则跳转
          navigate(targetPath);
        }
      }
    } catch (error) {
      console.log('getUserInfoFetchData error', error)

      enqueueSnackbar('Login Failed', { variant: 'warning' });
    } finally {
      // console.log('getUserInfoFetchData finally')
    }
  }

  /* 设置按钮弹窗 */
  const [settingBtnAnchorEl, setSettingBtnAnchorEl] = useState(null);
  const settingBtnOpen = Boolean(settingBtnAnchorEl);
  const handleSettingBtnClickListItem = (event) => {
    setSettingBtnAnchorEl(event.currentTarget);
  };

  const handleSettingBtnClose = () => {
    setSettingBtnAnchorEl(null);
  };
  

  /* 已连接按钮更多弹窗 */
  const [congregateAnchorEl, setCongregateAnchorEl] = useState(null);
  const congregateOpen = Boolean(congregateAnchorEl);
  const handleCongregateClickListItem = (event) => {
    setCongregateAnchorEl(event.currentTarget);
  };

  const handleCongregateClose = () => {
    setCongregateAnchorEl(null);
  };

  const handleCongregatePopupItemClick = (itemId) => {
    if (itemId === 'Rebate') {
      // 目标路径
      // const targetPath = '/referral';

      // // 判断当前路径是否是目标路径
      // if (location.pathname !== targetPath) {
      //   // 如果不是目标路径，则跳转
      //   navigate(targetPath);
      // }
    } else if (itemId === 'Disconnect') {
      homeStore.handleDisconnectTgUserData()
    } else if (itemId === 'TransferFunds') {
      // console.log('TransferFunds')

      homeStore.handleOpenTransferFundsModalClick()
    }
    handleCongregateClose()
  }

  // 弹出提示框
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant) => () => {
    // variant could be success, error, warning, info, or default
    let tipsMsg = 'Address copied to clipboard!'
    if (variant.variant === 'warning') {
      tipsMsg = 'Failed to copy address.'
    }
    enqueueSnackbar(tipsMsg, { variant });
  };

  return (
    // <PageWrap>
    //   <PageMain>
    <>
        {
          !isMobile ?
          (<PagePCHeaderWrap>
            <PagePCHeaderLeft>
              {/* /?chain=eth */}
              <PagePCHeaderLeftLogo href={'/'}>
                <PagePCHeaderLeftLogoImg src={XlionXLogo_cut_up_down}></PagePCHeaderLeftLogoImg>
              </PagePCHeaderLeftLogo>

              {/* <PagePCHeaderLeftList>
                <PagePCHeaderLeftListItem target="_self" href="/new-pair?chain=eth">新币</PagePCHeaderLeftListItem>
                <PagePCHeaderLeftListItem target="_self" href="/new-pair?chain=eth">热门</PagePCHeaderLeftListItem>
                <PagePCHeaderLeftListItem target="_self" href="/new-pair?chain=eth">探索</PagePCHeaderLeftListItem>
                <PagePCHeaderLeftListItem target="_self" href="/new-pair?chain=eth">持仓</PagePCHeaderLeftListItem>
                <PagePCHeaderLeftListItem target="_self" href="/new-pair?chain=eth">关注</PagePCHeaderLeftListItem>
              </PagePCHeaderLeftList> */}
            </PagePCHeaderLeft>

            <PagePCHeaderMiddle>
              <PagePCHeaderMiddleContent>
                <PagePCHeaderMiddleInputWrap onClick={handleSearchModalOpen}>
                  <PagePCHeaderMiddleInputSearchIconWrap>
                    <PagePCHeaderMiddleInputSearchIcon></PagePCHeaderMiddleInputSearchIcon>
                  </PagePCHeaderMiddleInputSearchIconWrap>

                  {/* 搜索代币/钱包 */}
                  <PagePCHeaderMiddleInputContent>Search</PagePCHeaderMiddleInputContent>

                  {/* <PagePCHeaderMiddleInputTip>
                    <PagePCHeaderMiddleInputTipContent>
                      Ctrl K
                    </PagePCHeaderMiddleInputTipContent>
                  </PagePCHeaderMiddleInputTip> */}
                </PagePCHeaderMiddleInputWrap>
              </PagePCHeaderMiddleContent>
            </PagePCHeaderMiddle>

            <PagePCHeaderRight>
              <PagePCHeaderRightChainSelectWrap
                id="demo-customized-button"
                aria-controls={chainSelectedOpen ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={chainSelectedOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleChainSelectedClickListItem}
              >
                <PagePCHeaderRightChainSelectContainer>
                  <PagePCHeaderRightChainSelectContent>
                    <PagePCHeaderRightChainSelectLeft>
                      <PagePCHeaderRightChainSelectLeftImg src={`https://dd.dexscreener.com/ds-data/chains/${homeStore.chainSelect.imgParams}.png`} />
                      {homeStore.chainSelect.shortName}
                    </PagePCHeaderRightChainSelectLeft>
                    <PagePCHeaderRightChainSelectIconWrap>
                      <PagePCHeaderRightChainSelectKeyboardArrowDownIcon></PagePCHeaderRightChainSelectKeyboardArrowDownIcon>
                    </PagePCHeaderRightChainSelectIconWrap>
                  </PagePCHeaderRightChainSelectContent>
                </PagePCHeaderRightChainSelectContainer>
              </PagePCHeaderRightChainSelectWrap>

              <PagePCHeaderRightChainSelectPopupWrap
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={chainSelectedAnchorEl}
                open={chainSelectedOpen}
                onClose={handleChainSelectedClose}

                elevation={0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    bgcolor: 'transparent',
                    mt: '8px',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px 0px'
                  },
                  "& .MuiList-root": {
                    py: "0px",
                  },
                }}
              >
                <PagePCHeaderRightChainSelectPopupList>
                  {
                    chainListData.map(item => (
                      <PagePCHeaderRightChainSelectPopupListItem
                        className={homeStore.chainSelect.id === item.id ? 'nav-link-active' : ''}
                        onClick={() => contentBoxListItemClick(item)}
                        key={item.id}
                      >
                        <PagePCHeaderRightChainSelectPopupListItemImg
                          alt={item.shortName}
                          src={`https://dd.dexscreener.com/ds-data/chains/${item.imgParams}.png`}
                          loading="lazy"
                        />
                          {item.shortName}
                      </PagePCHeaderRightChainSelectPopupListItem>
                    ))
                  }
                </PagePCHeaderRightChainSelectPopupList>
              </PagePCHeaderRightChainSelectPopupWrap>

              <PagePCHeaderRightSettingBtn
                id="demo-customized-button"
                aria-controls={settingBtnOpen ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={settingBtnOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleSettingBtnClickListItem}
              >
                <PagePCHeaderRightSettingBtnContent>
                  <PagePCHeaderRightSettingBtnIconWrap>
                    <PagePCHeaderRightSettingsOutlinedIcon></PagePCHeaderRightSettingsOutlinedIcon>
                  </PagePCHeaderRightSettingBtnIconWrap>
                </PagePCHeaderRightSettingBtnContent>
              </PagePCHeaderRightSettingBtn>

              <PagePCHeaderRightChainSelectPopupWrap
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={settingBtnAnchorEl}
                open={settingBtnOpen}
                onClose={handleSettingBtnClose}

                elevation={0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    bgcolor: 'transparent',
                    mt: '8px',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px 0px'
                  },
                  "& .MuiList-root": {
                    py: "0px",
                  },
                }}
              >
                <PagePCHeaderRightSettingBtnPopupList>
                  {/* <PagePCHeaderRightSettingBtnPopupListItem>
                    <PagePCHeaderRightSettingBtnPopupListItemContent>
                      通知设置
                      <PagePCHeaderRightSettingBtnPopupNotifyIconWrap>
                        <PagePCHeaderRightSettingBtnPopupNotifyKeyboardArrowDownIcon></PagePCHeaderRightSettingBtnPopupNotifyKeyboardArrowDownIcon>
                      </PagePCHeaderRightSettingBtnPopupNotifyIconWrap>
                    </PagePCHeaderRightSettingBtnPopupListItemContent>
                  </PagePCHeaderRightSettingBtnPopupListItem> */}
                  {/* <PagePCHeaderRightSettingBtnPopupListItem>
                    <PagePCHeaderRightSettingBtnPopupListItemContent>
                      语言
                    </PagePCHeaderRightSettingBtnPopupListItemContent>
                  </PagePCHeaderRightSettingBtnPopupListItem> */}
                  <PagePCHeaderRightSettingBtnPopupListItem>
                    <PagePCHeaderRightSettingBtnPopupListItemContent>
                      Dark Model
                    <PagePCHeaderRightSettingBtnPopupThemeSwitch size="small" onChange={changeThemeClick} checked={isDarkMode} />
                    </PagePCHeaderRightSettingBtnPopupListItemContent>
                  </PagePCHeaderRightSettingBtnPopupListItem>
                </PagePCHeaderRightSettingBtnPopupList>
              </PagePCHeaderRightChainSelectPopupWrap>

              <PagePCHeaderRightConnectStatusWrap>
                <PagePCHeaderRightConnectStatusContainer>
                  <PagePCHeaderRightConnectStatusWrap>
                    <PagePCHeaderRightLinkWalletConectContent>

                    </PagePCHeaderRightLinkWalletConectContent>

                    <PagePCHeaderRightDisconnectContent>

                    </PagePCHeaderRightDisconnectContent>

                    {
                      homeStore.tgUserToken ?
                        (<PagePCHeaderRightCongregateContent>
                          <PagePCHeaderRightCongregateTgWrap>
                            <PagePCHeaderRightCongregateTgIconWrap>
                              <PagePCHeaderRightCongregateTelegramIcon></PagePCHeaderRightCongregateTelegramIcon>
                            </PagePCHeaderRightCongregateTgIconWrap>
                          </PagePCHeaderRightCongregateTgWrap>

                          <PagePCHeaderRightCongregateWalletInfoWrap>
                            <PagePCHeaderRightCongregateWalletBalanceWrap>
                              <PagePCHeaderRightCongregateWalletBalanceDehazeIcon></PagePCHeaderRightCongregateWalletBalanceDehazeIcon>
                              <PagePCHeaderRightCongregateWalletBalanceNum>{formatNumberUSDBySize(multiply(homeStore.tgUserInfo?.balance, homeStore.tgUserInfo?.price))}</PagePCHeaderRightCongregateWalletBalanceNum>
                            </PagePCHeaderRightCongregateWalletBalanceWrap>

                            <PagePCHeaderRightCongregateWalletAddressWrap>
                              <PagePCHeaderRightCongregateWalletAddressContent>
                                {formatStringWithEllipsis(homeStore.tgUserInfo.walletAddress)}

                                <CopyToClipboard
                                  text={homeStore.tgUserInfo.walletAddress}
                                  onCopy={handleClickVariant('success')}
                                >
                                  <PagePCHeaderRightCongregateWalletAddressContentContentCopyIcon></PagePCHeaderRightCongregateWalletAddressContentContentCopyIcon>
                                </CopyToClipboard>
                              </PagePCHeaderRightCongregateWalletAddressContent>
                            </PagePCHeaderRightCongregateWalletAddressWrap>
                          </PagePCHeaderRightCongregateWalletInfoWrap>

                          <PagePCHeaderRightCongregateMoreWrap
                            id="demo-customized-button"
                            aria-controls={congregateOpen ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={congregateOpen ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleCongregateClickListItem}
                          >
                            <PagePCHeaderRightCongregateMoreIconWrap>
                              <PagePCHeaderRightCongregateMoreKeyboardArrowDownIcon isRotate={congregateOpen}></PagePCHeaderRightCongregateMoreKeyboardArrowDownIcon>
                            </PagePCHeaderRightCongregateMoreIconWrap>
                          </PagePCHeaderRightCongregateMoreWrap>

                          <PagePCHeaderRightCongregateMorePopupWrap
                            id="demo-customized-menu"
                            MenuListProps={{
                              'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={congregateAnchorEl}
                            open={congregateOpen}
                            onClose={handleCongregateClose}

                            elevation={0}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            sx={{
                              '& .MuiPaper-root': {
                                bgcolor: 'transparent',
                                mt: '22px',
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px 0px'
                              },
                              "& .MuiList-root": {
                                py: "0px"
                              },
                            }}
                          >
                            <PagePCHeaderRightCongregateMorePopupList>
                              <PagePCHeaderRightCongregateMorePopupListLink
                                to={`/address/${homeStore.tgUserInfo.walletAddress}`}
                                onClick={() => handleCongregatePopupItemClick('MyWallet')}
                              >
                                My Wallet
                              </PagePCHeaderRightCongregateMorePopupListLink>
                              <PagePCHeaderRightCongregateMorePopupListLink to={'/referral'} onClick={() => handleCongregatePopupItemClick('Rebate')}>
                                Referral
                              </PagePCHeaderRightCongregateMorePopupListLink>
                              <PagePCHeaderRightCongregateMorePopupListLink onClick={() => handleCongregatePopupItemClick('TransferFunds')}>
                                Transfer Funds
                              </PagePCHeaderRightCongregateMorePopupListLink>
                              {/* <PagePCHeaderRightCongregateMorePopupListLink>
                                Import or Export Private Key
                              </PagePCHeaderRightCongregateMorePopupListLink> */}
                              {/* <PagePCHeaderRightCongregateMorePopupListItem>
                                TG Alert
                              </PagePCHeaderRightCongregateMorePopupListItem> */}
                              {/* <PagePCHeaderRightCongregateMorePopupListItem>
                                Add Twitter
                              </PagePCHeaderRightCongregateMorePopupListItem> */}
                              <PagePCHeaderRightCongregateMorePopupListItem onClick={() => handleCongregatePopupItemClick('Disconnect')}>
                                Disconnect
                              </PagePCHeaderRightCongregateMorePopupListItem>
                            </PagePCHeaderRightCongregateMorePopupList>
                          </PagePCHeaderRightCongregateMorePopupWrap>
                        </PagePCHeaderRightCongregateContent>) :
                        // (<PagePCHeaderRightConnectContent href={'https://t.me/test_foxgun_bot?start=_'} target="_blank" rel="noopener noreferrer">
                        (<PagePCHeaderRightConnectContent onClick={handleTgLoginModalOpen}>
                          <PagePCHeaderRightConnectBtn>Connect</PagePCHeaderRightConnectBtn>
                        </PagePCHeaderRightConnectContent>)
                    }

                  </PagePCHeaderRightConnectStatusWrap>
                </PagePCHeaderRightConnectStatusContainer>
              </PagePCHeaderRightConnectStatusWrap>
              
            </PagePCHeaderRight>

          </PagePCHeaderWrap>)
          :
          (<PageMobileHeaderWrap>
            <PageMobileHeaderContainer>
              <PageMobileHeaderContent>
                <PageMobileHeaderHomeLink href={'/'}>
                  <PageMobileHeaderHomeLinkLogo src={logo_small} />
                </PageMobileHeaderHomeLink>

                <PageMobileHeaderMainBody>
                  <PageMobileHeaderMainChainSelectWrap
                    id="demo-customized-button"
                    aria-controls={chainSelectedOpen ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={chainSelectedOpen ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleChainSelectedClickListItem}
                  >
                    <PageMobileHeaderMainChainSelectContainer>
                      <PageMobileHeaderMainChainSelectContent>
                        <PageMobileHeaderMainChainSelectLeft>
                          <PageMobileHeaderMainChainSelectLeftImg src={`https://dd.dexscreener.com/ds-data/chains/${homeStore.chainSelect.imgParams}.png`} />
                          {homeStore.chainSelect.shortName}
                        </PageMobileHeaderMainChainSelectLeft>
                        <PageMobileHeaderMainChainSelectIconWrap>
                          <PageMobileHeaderMainChainSelectKeyboardArrowDownIcon></PageMobileHeaderMainChainSelectKeyboardArrowDownIcon>
                        </PageMobileHeaderMainChainSelectIconWrap>
                      </PageMobileHeaderMainChainSelectContent>
                    </PageMobileHeaderMainChainSelectContainer>
                  </PageMobileHeaderMainChainSelectWrap>

                  <PageMobileHeaderMainChainSelectPopupWrap
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={chainSelectedAnchorEl}
                    open={chainSelectedOpen}
                    onClose={handleChainSelectedClose}

                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        bgcolor: 'transparent',
                        mt: '8px',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px 0px'
                      },
                      "& .MuiList-root": {
                        py: "0px"
                      },
                    }}
                  >
                    <PageMobileHeaderMainChainSelectPopupList>
                      {
                        chainListData.map(item => (
                          <PageMobileHeaderMainChainSelectPopupListItem
                            className={homeStore.chainSelect.id === item.id ? 'nav-link-active' : ''}
                            onClick={() => contentBoxListItemClick(item)}
                            key={item.id}
                          >
                            <PageMobileHeaderMainChainSelectPopupListItemImg
                              alt={item.shortName}
                              src={`https://dd.dexscreener.com/ds-data/chains/${item.imgParams}.png`}
                              loading="lazy"
                            />
                              {item.shortName}
                          </PageMobileHeaderMainChainSelectPopupListItem>
                        ))
                      }
                    </PageMobileHeaderMainChainSelectPopupList>
                  </PageMobileHeaderMainChainSelectPopupWrap>
                  
                  <PageMobileHeaderMainSearchIconWrap onClick={handleSearchModalOpen}>
                    <PageMobileHeaderMainSearchIcon></PageMobileHeaderMainSearchIcon>
                  </PageMobileHeaderMainSearchIconWrap>

                  <PageMobileHeaderMainSettingBtn
                    id="demo-customized-button"
                    aria-controls={settingBtnOpen ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={settingBtnOpen ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleSettingBtnClickListItem}
                  >
                    <PageMobileHeaderMainSettingBtnContent>
                      <PageMobileHeaderMainSettingBtnIconWrap>
                        <PageMobileHeaderMainSettingsOutlinedIcon></PageMobileHeaderMainSettingsOutlinedIcon>
                      </PageMobileHeaderMainSettingBtnIconWrap>
                    </PageMobileHeaderMainSettingBtnContent>
                  </PageMobileHeaderMainSettingBtn>

                  <PageMobileHeaderMainChainSelectPopupWrap
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={settingBtnAnchorEl}
                    open={settingBtnOpen}
                    onClose={handleSettingBtnClose}

                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        bgcolor: 'transparent',
                        mt: '8px',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px 0px'
                      },
                      "& .MuiList-root": {
                        py: "0px",
                      },
                    }}
                  >
                    <PageMobileHeaderMainSettingBtnPopupList>
                      {/* <PageMobileHeaderMainSettingBtnPopupListItem>
                        <PageMobileHeaderMainSettingBtnPopupListItemContent>
                          通知设置
                          <PageMobileHeaderMainSettingBtnPopupNotifyIconWrap>
                            <PageMobileHeaderMainSettingBtnPopupNotifyKeyboardArrowDownIcon></PageMobileHeaderMainSettingBtnPopupNotifyKeyboardArrowDownIcon>
                          </PageMobileHeaderMainSettingBtnPopupNotifyIconWrap>
                        </PageMobileHeaderMainSettingBtnPopupListItemContent>
                      </PageMobileHeaderMainSettingBtnPopupListItem> */}
                      {/* <PageMobileHeaderMainSettingBtnPopupListItem>
                        <PageMobileHeaderMainSettingBtnPopupListItemContent>
                          语言
                        </PageMobileHeaderMainSettingBtnPopupListItemContent>
                      </PageMobileHeaderMainSettingBtnPopupListItem> */}
                      <PageMobileHeaderMainSettingBtnPopupListItem>
                        <PageMobileHeaderMainSettingBtnPopupListItemContent>
                          Dark Model
                        <PageMobileHeaderMainSettingBtnPopupThemeSwitch size="small" onChange={changeThemeClick} checked={isDarkMode} />
                        </PageMobileHeaderMainSettingBtnPopupListItemContent>
                      </PageMobileHeaderMainSettingBtnPopupListItem>
                    </PageMobileHeaderMainSettingBtnPopupList>
                  </PageMobileHeaderMainChainSelectPopupWrap>

                  <PageMobileHeaderMainConnectStatusWrap>
                    <PageMobileHeaderMainConnectStatusContainer>
                      <PageMobileHeaderMainConnectStatusWrap>
                        <PageMobileHeaderMainLinkWalletConectContent>

                        </PageMobileHeaderMainLinkWalletConectContent>

                        <PageMobileHeaderMainDisconnectContent>

                        </PageMobileHeaderMainDisconnectContent>

                        {
                          homeStore.tgUserToken ?
                            (<PageMobileHeaderMainCongregateContent>
                              <PageMobileHeaderMainCongregateTgWrap>
                                <PageMobileHeaderMainCongregateTgIconWrap>
                                  <PageMobileHeaderMainCongregateTelegramIcon></PageMobileHeaderMainCongregateTelegramIcon>
                                </PageMobileHeaderMainCongregateTgIconWrap>
                              </PageMobileHeaderMainCongregateTgWrap>
      
                              <PageMobileHeaderMainCongregateWalletInfoWrap>
                                <PageMobileHeaderMainCongregateWalletBalanceWrap>
                                  <PageMobileHeaderMainCongregateWalletBalanceDehazeIcon></PageMobileHeaderMainCongregateWalletBalanceDehazeIcon>
                                  <PageMobileHeaderMainCongregateWalletBalanceNum>{formatNumberUSDBySize(multiply(homeStore.tgUserInfo?.balance, homeStore.tgUserInfo?.price))}</PageMobileHeaderMainCongregateWalletBalanceNum>
                                </PageMobileHeaderMainCongregateWalletBalanceWrap>
                              </PageMobileHeaderMainCongregateWalletInfoWrap>
      
                              <PageMobileHeaderMainCongregateMoreWrap
                                id="demo-customized-button"
                                aria-controls={congregateOpen ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={congregateOpen ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleCongregateClickListItem}
                              >
                                <PageMobileHeaderMainCongregateMoreIconWrap>
                                  <PageMobileHeaderMainCongregateMoreKeyboardArrowDownIcon isRotate={congregateOpen}></PageMobileHeaderMainCongregateMoreKeyboardArrowDownIcon>
                                </PageMobileHeaderMainCongregateMoreIconWrap>
                              </PageMobileHeaderMainCongregateMoreWrap>
      
                              <PageMobileHeaderMainCongregateMorePopupWrap
                                id="demo-customized-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={congregateAnchorEl}
                                open={congregateOpen}
                                onClose={handleCongregateClose}
      
                                elevation={0}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                sx={{
                                  '& .MuiPaper-root': {
                                    bgcolor: 'transparent',
                                    mt: '22px',
                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px 0px'
                                  },
                                  "& .MuiList-root": {
                                    py: "0px",
                                    // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 4px 8px 0px',
                                  },
                                }}
                              >
                                <PageMobileHeaderMainCongregateMorePopupList>
                                  <PageMobileHeaderMainCongregateMorePopupListLink
                                    to={`/address/${homeStore.tgUserInfo.walletAddress}`}
                                    onClick={() => handleCongregatePopupItemClick('MyWallet')}
                                  >
                                    My Wallet
                                  </PageMobileHeaderMainCongregateMorePopupListLink>
                                  <PageMobileHeaderMainCongregateMorePopupListLink to={'/referral'} onClick={() => handleCongregatePopupItemClick('Rebate')}>
                                    Referral
                                  </PageMobileHeaderMainCongregateMorePopupListLink>
                                  <PageMobileHeaderMainCongregateMorePopupListLink>
                                    Transfer Funds
                                  </PageMobileHeaderMainCongregateMorePopupListLink>
                                  {/* <PageMobileHeaderMainCongregateMorePopupListLink>
                                    Import or Export Private Key
                                  </PageMobileHeaderMainCongregateMorePopupListLink> */}
                                  {/* <PageMobileHeaderMainCongregateMorePopupListItem>
                                    TG Alert
                                  </PageMobileHeaderMainCongregateMorePopupListItem> */}
                                  {/* <PageMobileHeaderMainCongregateMorePopupListItem>
                                    Add Twitter
                                  </PageMobileHeaderMainCongregateMorePopupListItem> */}
                                  <PageMobileHeaderMainCongregateMorePopupListItem onClick={() => handleCongregatePopupItemClick('Disconnect')}>
                                    Disconnect
                                  </PageMobileHeaderMainCongregateMorePopupListItem>
                                </PageMobileHeaderMainCongregateMorePopupList>
                              </PageMobileHeaderMainCongregateMorePopupWrap>
                            </PageMobileHeaderMainCongregateContent>) :
                            (<PageMobileHeaderMainConnectContent onClick={handleTgLoginModalOpen}>
                              <PageMobileHeaderMainConnectBtn>Connect</PageMobileHeaderMainConnectBtn>
                            </PageMobileHeaderMainConnectContent>)
                        }
                      </PageMobileHeaderMainConnectStatusWrap>
                    </PageMobileHeaderMainConnectStatusContainer>
                  </PageMobileHeaderMainConnectStatusWrap>
                </PageMobileHeaderMainBody>
              </PageMobileHeaderContent>
            </PageMobileHeaderContainer>
          </PageMobileHeaderWrap>)
        }

        {/* <PageBodyWrap> */}
          {/* 渲染嵌套的子路由 */}
          {/* <Outlet />
        </PageBodyWrap> */}
      {/* </PageMain>
    </PageWrap> */}
    </>
  )
})

export default HomeTopMenuPage
