import { makeAutoObservable, runInAction  } from 'mobx';
import axios from 'axios';

import { request } from '@/services/axios'
import { formatNumberBySize, formatPercentageBySize, divideAndRound } from '@/utils/numberUtils'
import { formatTimeAgo } from '@/utils/dateUtils'
import { formatStringWithEllipsis, capitalizeFirstLetter } from '@/utils/stringUtils'
import { chainListData } from '@/constants/pairsData'

import homeStore from './homeStore'

class DealStore {
  /* 币种详情 */
  coinDetailData = null; // 用于存储请求到的数据
  coinDetailLoading = false; // 请求状态：加载中
  coinDetailError = null; // 错误信息

  /* 币种Transactions */
  coinTransactionsData = null; // 用于存储请求到的数据
  coinTransactionsLoading = false; // 请求状态：加载中
  coinTransactionsError = null; // 错误信息
  coinTransactionsPage = 1;
  coinTransactionsPageSize = 50;

  /* 币种Top Traders */
  coinTopTradersData = null; // 用于存储请求到的数据
  coinTopTradersLoading = false; // 请求状态：加载中
  coinTopTradersError = null; // 错误信息
  
  /* 币种token Transactions */
  coinTokenTransactionsData = null; // 用于存储请求到的数据
  coinTokenTransactionsLoading = false; // 请求状态：加载中
  coinTokenTransactionsError = null; // 错误信息

  /* 币种整体概况 */
  coinOverviewData = null
  /* 币种Holders */
  coinHoldersData = null; // 用于存储请求到的数据
  /* 币种Liquidity Providers */
  coinLiquidityProvidersData = null; // 用于存储请求到的数据
  coinOverviewLoading = false; // 请求状态：加载中
  coinOverviewError = null; // 错误信息

  constructor() {
    makeAutoObservable(this); // 将类转换为 MobX 响应式对象
  }

  // 重置所有数据为初始值
  reset() {
    this.coinDetailData = null;
    this.coinDetailLoading = false;
    this.coinDetailError = null;

    this.coinTransactionsData = null;
    this.coinTransactionsLoading = false;
    this.coinTransactionsError = null;
    this.coinTransactionsPage = 1;
    this.coinTransactionsPageSize = 50;

    this.coinTopTradersData = null;
    this.coinTopTradersLoading = false;
    this.coinTopTradersError = null;

    this.coinTokenTransactionsData = null;
    this.coinTokenTransactionsLoading = false;
    this.coinTokenTransactionsError = null;

    this.coinOverviewData = null;
    this.coinHoldersData = null;
    this.coinLiquidityProvidersData = null;
    this.coinOverviewLoading = false;
    this.coinOverviewError = null;
  }

  async fetchCoinDetailData(params, isFirstFetch) {
    if (isFirstFetch) {
      this.coinDetailLoading = true;
    }
    this.coinDetailError = null;

    try {
      const res = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${params}`)
      const { data } = res
      const { pairs } = data
      // 有多个池子，取第一条
      const [firstPair] = pairs
      
      const getChainItem = chainListData.find(item => item.chainId === firstPair.chainId)
      firstPair.chainIdText = capitalizeFirstLetter(firstPair.chainId)
      firstPair.chainIdHref = getChainItem.homeUrlParams
      firstPair.chainIdHomeParams = getChainItem.homeParams
      firstPair.chainIdImgText = `https://dd.dexscreener.com/ds-data/chains/${firstPair.chainId}.png`
      firstPair.dexIdText = capitalizeFirstLetter(firstPair.dexId)
      firstPair.dexIdImgText = `https://dd.dexscreener.com/ds-data/dexes/${firstPair.dexId}.png`

      firstPair.liquidity.usdText = formatNumberBySize(firstPair?.liquidity?.usd)
      firstPair.fdvText = formatNumberBySize(firstPair.fdv)
      firstPair.marketCapText = formatNumberBySize(firstPair.marketCap)

      firstPair.priceChange.m5Text = formatPercentageBySize(firstPair?.priceChange?.m5);
      firstPair.priceChange.h1Text = formatPercentageBySize(firstPair?.priceChange?.h1);
      firstPair.priceChange.h6Text = formatPercentageBySize(firstPair?.priceChange?.h6);
      firstPair.priceChange.h24Text = formatPercentageBySize(firstPair?.priceChange?.h24);

      firstPair.pairCreatedAtText = formatTimeAgo(firstPair.pairCreatedAt)

      firstPair.pairAddressText = formatStringWithEllipsis(firstPair.pairAddress)
      firstPair.pairAddressNavText = getChainItem.chainAccountWebsite + firstPair.pairAddress
      firstPair.baseToken.addressText = formatStringWithEllipsis(firstPair.baseToken.address)
      firstPair.baseToken.addressNavText = getChainItem.chainTokenWebsite + firstPair.baseToken.address
      firstPair.quoteToken.addressText = formatStringWithEllipsis(firstPair.quoteToken.address)
      firstPair.quoteToken.addressNavText = getChainItem.chainTokenWebsite + firstPair.quoteToken.address

      firstPair.baseToken.XSearchSymbol = 'https://twitter.com/search?q=%24' + firstPair.baseToken.symbol
      firstPair.baseToken.XSearchCA = 'https://twitter.com/search?q=' + firstPair.baseToken.address

      firstPair.pairnumText = divideAndRound(firstPair.marketCap, firstPair.priceUsd)
      firstPair.chainPriceText = divideAndRound(firstPair.priceUsd, firstPair.priceNative)
      // 使用 runInAction 更新 observable 数据
      runInAction(() => {
        this.coinDetailData = firstPair;
      });
    } catch (error) {
      console.log('error', error)
      runInAction(() => {
        this.coinDetailError = error.message;
      });
    } finally {
      runInAction(() => {
        this.coinDetailLoading = false;
      });
    }
  }
  // 每秒请求一次  数据
  startTokenDetailsRequest(tokenAddress) {
    this.fetchCoinDetailData(tokenAddress, true); // 首次请求
    this.tokenDetailsIntervalId = setInterval(() => {
      this.fetchCoinDetailData(tokenAddress);
    }, 10000);
  }
  // 停止请求  数据
  stopTokenDetailsRequest() {
    if (this.tokenDetailsIntervalId) {
      clearInterval(this.tokenDetailsIntervalId);
    }
  }

  changeCoinTransactionsPages({page, isCheckedTokenTransactionsMakerBtn, cryptoTokenAddress, tokenTransactionsWalletAddress}) {
    runInAction(() => {
      this.coinTransactionsPage = page
      if (!isCheckedTokenTransactionsMakerBtn) {
        dealStore.fetchCoinTransactionsData(cryptoTokenAddress); // 每秒请求一次
      } else {
        dealStore.fetchCoinTokenTransactionsData(cryptoTokenAddress, tokenTransactionsWalletAddress); // 每秒请求一次
      }
    });
  }

  /* 请求Transactions表格数据 */
  async fetchCoinTransactionsData(tokenAddress) {
    this.coinTransactionsLoading = true;
    this.coinTransactionsError = null;
    try {
      const res = await request(`dex/chain/${homeStore.chainSelect.fetchName}/findTradesTnxPage`, {
        tokenAddress,
        count: this.coinTransactionsPageSize,
        page: this.coinTransactionsPage
      })
      const { data } = res
      const uniqueData = data.reduce((acc, current) => {
        // 检查 acc 中是否已经包含该 transactionSignature
        if (!acc.some(item => item.transactionSignature === current.transactionSignature)) {
          acc.push(current); // 如果没有，添加到结果数组中
        }
        return acc;
      }, []);

      runInAction(() => {
        this.coinTransactionsData = uniqueData;
      });
    } catch (error) {
      runInAction(() => {
        this.coinTransactionsError = error.message;
      });
    } finally {
      runInAction(() => {
        this.coinTransactionsLoading = false;
      });
    }
  }

  /* 请求Top Traders表格数据 */
  async fetchCoinTopTradersData(tokenAddress) {
    this.coinTopTradersLoading = true;
    this.coinTopTradersError = null;

    try {
      const res = await request(`dex/chain/${homeStore.chainSelect.fetchName}/findTopTradersPage`, {
        tokenAddress
      })
      const { data } = res
      runInAction(() => {
        this.coinTopTradersData = data;
      });
    } catch (error) {
      runInAction(() => {
        this.coinTopTradersError = error.message;
      });
    } finally {
      runInAction(() => {
        this.coinTopTradersLoading = false;
      });
    }
  }

  /* 请求Top Traders按钮某个token Transactions表格数据 */
  async fetchCoinTokenTransactionsData(tokenAddress, walletAddress) {
    this.coinTokenTransactionsLoading = true;
    this.coinTokenTransactionsError = null;

    try {
      const res = await request(`dex/chain/${homeStore.chainSelect.fetchName}/findTraderDetailTokenPage`, {
        walletAddress,
        tokenAddress,
        count: this.coinTransactionsPageSize,
        page: this.coinTransactionsPage
      })
      const { data } = res
      runInAction(() => {
        this.coinTokenTransactionsData = data;
      });
    } catch (error) {
      runInAction(() => {
        this.coinTokenTransactionsError = error.message;
      });
    } finally {
      runInAction(() => {
        this.coinTokenTransactionsLoading = false;
      });
    }
  }

  /* 请求Holder和Liquidity Providers表格数据 */
  async fetchCoinOverviewData(tokenAddress) {
    this.coinOverviewLoading = true;
    this.coinOverviewError = null;

    try {
      const res = await request(`dex/chain/${homeStore.chainSelect.fetchName}/findDexHoldersTop`, {
        tokenAddress
      })
      const { data } = res
      const { ll, holders } = data
      runInAction(() => {
        this.coinOverviewData = data;
        this.coinHoldersData = holders;
        this.coinLiquidityProvidersData = ll;
      });
    } catch (error) {
      runInAction(() => {
        this.coinOverviewError = error.message;
      });
    } finally {
      runInAction(() => {
        this.coinOverviewLoading = false;
      });
    }
  }

  
  /* 请求SOL或ETH价格数据 */
  async fetchCoinPriceData() {
    try {
      const token = homeStore.chainSelect.chainId === 'solana' ? 'So11111111111111111111111111111111111111112' : '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
      const res = await axios({
        url: `https://api.dexscreener.com/latest/dex/tokens/${token}`,
        method: 'get'
      })
      const { pairs } = res.data
      const getPrice = pairs[0].priceUsd
      runInAction(() => {
        homeStore.handleChangePairsPriceUsdData(getPrice)
      });
    } catch (error) {
      runInAction(() => {
      });
    } finally {
      runInAction(() => {
      });
    }
  }
}

const dealStore = new DealStore();
export default dealStore;
