import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // 去掉大写字母
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 0
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: { default: '#f9f9f9' }, // light模式下的背景色
    text: { primary: '#404040' }, // light模式下的文字颜色
  },
});

export const darkTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // 去掉大写字母
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 0
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#1d1d22',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: { default: '#0d0d0d' }, // dark模式下的背景色
    text: { primary: '#ffffff' }, // dark模式下的文字颜色
  },
});
