// store/Counter.ts
import {action, makeAutoObservable, runInAction} from 'mobx'
import { makePersistable } from "mobx-persist-store";
import { enqueueSnackbar } from "notistack";  // 引入 notistack 的 enqueueSnackbar 方法

import { request } from '@/services/axios'
import { chainListData } from '@/constants/pairsData'
import { multiply, formatAmountWithoutRounding, formatNumberUSDBySize } from '@/utils/numberUtils'

const moveToFirst = (arr) => {
  // 查找默认项
  const defaultItem = arr.find(item => item.default === "1");
  if (defaultItem) {
    // 移除默认项
    const filteredArr = arr.filter(item => item.default !== "1");
    // 将默认项放到数组的第一项
    return [defaultItem, ...filteredArr];
  }
  return arr; // 如果没有找到"default": "1"，返回原数组
};

class HomeStore {
  constructor(){
    makeAutoObservable(this);

    // 持久化存储
    makePersistable(this, {
      name: 'homeStore',
      properties: ['isDarkMode', 'isCloseMenu', 'chainSelect', 'appVersion'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => { // persist 完成的回调，在这里可以执行一些拿到数据后需要执行的操作，如果在页面上要判断是否完成persist，使用 isHydrated
        // console.log(persistStore);
    }));

    // 钱包信息
    makePersistable(this, {
      name: 'walletInfo',
      properties: ['tgUserToken', 'tgUserInfo', 'pairsPriceUsd', 'tradeStateList', 'limitTradeStateList'],
      storage: window.localStorage,
    })
  }

  /* 首页 */
  appVersion = "1.0.0"; // 当前应用版本号
  isDarkMode = true
  chainSelect = chainListData[0]
  isCloseMenu = false
  isOpenTransferFundsModal = false
  transferFundsModalContentName = ''

  handleChangeThemeClick = () => {
    // console.log('handleChangeThemeClick', this)
    this.isDarkMode = !this.isDarkMode
  }
  changeChainSelect(chain) {
    this.chainSelect = chain
  }
  handleCloseMenuClick = () => {
    this.isCloseMenu = !this.isCloseMenu
  }
  fetchUserBonusInfoData = async () => {
    try {
      const res = await request('lionx/user/bonus/info', {
        token: this.tgUserToken,
        chainName: this.chainSelect.requestParams,
      })
      // console.log('request', res, res.data)
      const { data } = res
      if (data) {
        runInAction(() => {
          this.handleChangeTgUserInfoData(data)
        });

      }

    } catch {

    } finally {
      
    }
  }

  handleOpenTransferFundsModalClick = (contentName) => {
    // console.log('contentName', contentName)
    if (typeof contentName === 'string') {
      this.transferFundsModalContentName = contentName
    }

    this.isOpenTransferFundsModal = true
  }

  handleCloseTransferFundsModalClick = () => {
    this.isOpenTransferFundsModal = false
    this.transferFundsModalContentName = ''
  }

  handleChangeTransferFundsModalNameClick = (contentName) => {
    // console.log('contentName', contentName)
    this.transferFundsModalContentName = contentName
  }

  // 获取当前应用版本
  getAppVersion() {
    return this.appVersion;
  }

  /* 用户 */
  isLoggedInStatus = 0
  tgUserToken = null
  tgUserInfo = null
  pairsPriceUsd = null
  tradeStateList = []
  limitTradeStateList = []
  userTradeWalletList = []
  userTradeWalletTotalBalance = {totalBalance: 0, totalBalanceUSD: 0}

  // 根据当前版本号来决定是否迁移数据
  checkVersionAndMigrate() {
    if (this.appVersion === "1.0.0") {
      // 执行数据迁移
    } else if (this.appVersion === "1.1.0") {
      // 执行新版本的数据迁移逻辑
    }
  }

  handleConnectTgUserData = () => {
    this.isLoggedInStatus = 1
  }

  handleDisconnectTgUserData = () => {
    this.isLoggedInStatus = 2
  }

  handleResetTgUserData = () => {
    this.isLoggedInStatus = 0
    this.tgUserToken = null
    this.tgUserInfo = null

    this.tradeStateList = []
    this.limitTradeStateList = []
    this.userTradeWalletList = []
    this.userTradeWalletTotalBalance = {totalBalance: 0, totalBalanceUSD: 0}
  }

  handleChangeTgUserData = (data) => {
    // console.log('handleChangeTgUserData', data)
    this.tgUserToken = data
  }

  handleChangeTgUserInfoData = (data) => {
    const balanceText = formatAmountWithoutRounding(data.balance, 4)
    const balanceUSD = multiply(data.balance, data.price)
    const balanceUSDText = formatNumberUSDBySize(balanceUSD)

    this.tgUserInfo = {...data, balanceText, balanceUSD, balanceUSDText}
  }

  handleChangePairsPriceUsdData = (data) => {
    // console.log('handleChangePairsPriceUsdData', data, this.chainSelect.id)

    // 确保 pairsPriceUsd 是一个对象，如果是 null 或 undefined，则初始化为空对象
    if (this.pairsPriceUsd === null || this.pairsPriceUsd === undefined) {
      this.pairsPriceUsd = {};
    }

    this.pairsPriceUsd[this.chainSelect.id] = data
  }

  handleAddTradeStateListData = (data) => {
    this.tradeStateList = [...this.tradeStateList, data]
  }
  handleRemoveTradeStateListData = (tnxHash) => {
    this.tradeStateList = this.tradeStateList.filter(item => item.tnxHash !== tnxHash)
  }

  handleAddLimitTradeStateListData = (data) => {
    this.limitTradeStateList = [...this.limitTradeStateList, data]
  }
  handleRemoveLimitTradeStateListData = (tnxHash) => {
    this.limitTradeStateList = this.limitTradeStateList.filter(item => item.tnxHash !== tnxHash)
  }

  /* 获取所有钱包 */
  fetchUserTradeGetWalletListData = async () => {
    try {
      const res = await request('lionx/user/trade/getWalletList', {
        chainName: this.chainSelect.requestParams,
        token: this.tgUserToken,
      })

      let list = res.data
      if (res.status === 200 && list) {
        // 计算 balance 总和
        const totalBalance = list.reduce((total, item) => {
          return total + parseFloat(item.balance);  // 使用 parseFloat 来确保是数字相加
        }, 0);  // 初始值是 0
        const totalBalanceUSD = multiply(totalBalance, this.tgUserInfo.price, 2)

        runInAction(() => {
          this.userTradeWalletTotalBalance = { totalBalance, totalBalanceUSD }
          this.userTradeWalletList = moveToFirst(list)
        })
      } else {
        enqueueSnackbar(res.message || 'Get Wallets Failed', { variant: 'default' });
      }
    } catch (error) {

    } finally {

    }
  }

  /* 切换钱包 */
  fetchUserTradeChangeWalletData = async (walletAddr) => {
    try {
      const res = await request('lionx/user/trade/changeWallet', {
        chainName: this.chainSelect.requestParams,
        token: this.tgUserToken,
        cgWalletAddr: walletAddr,
      })

      const data = res
      if (data.status === 200 && data.data) {
        this.fetchUserTradeSyncWallet()
        this.fetchUserBonusInfoData()
        const updatedList = this.userTradeWalletList.map(item => {
          if (item.walletAddr === walletAddr) {
            return {...item, default: '1'}
          } else {
            return {...item, default: '0'}
          }
        })

        runInAction(() => {
          this.userTradeWalletList = moveToFirst(updatedList)
        })
      } else {
        enqueueSnackbar(data.message || 'Change Wallets Failed', { variant: 'default' });
      }
    } catch (error) {

    } finally {

    }
  }

  
  /* 钱包余额同步 */
  fetchUserTradeSyncWallet = async () => {
    try {
      const res = await request('lionx/user/trade/syncWallet', {
        chainName: this.chainSelect.requestParams,
        token: this.tgUserToken,
      })

      const data = res
      if (data.status === 200) {
        // console.log(data)
        const balance = data.data
        runInAction(() => {
          this.handleChangeTgUserInfoData({...this.tgUserInfo, balance})
        })
      } else {
        enqueueSnackbar(data.message, { variant: 'default' });
      }
    } catch (error) {

    } finally {

    }
  }
  
}
const homeStore = new HomeStore()
export default homeStore
