/** @jsxImportSource @emotion/react */
import { useState, useEffect, Suspense, lazy } from 'react'
import { Global, css } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Routes, Route, useNavigate } from "react-router-dom"
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';

import { useStore } from '@/store'
import { request } from '@/services/axios'

import { lightTheme, darkTheme } from '@/styles/theme';

import HomeTopMenuPage from '@/pages/HomeTopMenuPage'
// import HomeLeftMenuPage from '@/pages/HomeMenuPage'
const HomeMainPage = lazy(() => import('@/pages/HomeMainPage'));
const WatchlistPage = lazy(() => import('@/pages/WatchlistPage'));
const DealPage = lazy(() => import('@/pages/Deal'));
const SearchPage = lazy(() => import('@/pages/SearchPage'));
const TgLoginPage = lazy(() => import('@/pages/TgLoginPage'));
const TgLoginPopup = lazy(() => import('@/pages/TgLoginPopup'));
const ReferralPage = lazy(() => import('@/pages/ReferralPage'));
const AddressPage = lazy(() => import('@/pages/AddressPage'));
const WalletManagerModal = lazy(() => import('@/pages/WalletManagerModal'));

function App() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();  // 获取 notistack 的 hook

  const { homeStore } = useStore()
  const { isOpenTransferFundsModal, fetchUserTradeSyncWallet } = homeStore

  // 切换主题
  const currentTheme = homeStore.isDarkMode ? darkTheme : lightTheme;


  useEffect(() => {
    if (homeStore.isLoggedInStatus === 2) {
      navigate('/');  // 跳转到首页
      homeStore.handleResetTgUserData();  // 重置登录状态
      enqueueSnackbar('Login Expired', { variant: 'warning' });
    }
  }, [homeStore.isLoggedInStatus, navigate]);

  useEffect(() => {
    let userBonusInfoIntervalId = null
    if (homeStore.tgUserToken) {
      homeStore.fetchUserBonusInfoData()
      userBonusInfoIntervalId = setInterval(() => {
        homeStore.fetchUserBonusInfoData()
      }, 30000);
    }
    return () => {
      if (userBonusInfoIntervalId) {
        clearInterval(userBonusInfoIntervalId)
      }
    }
  }, [homeStore.tgUserToken])

  useEffect(() => {
    /* 获取所有钱包 */
    if (homeStore.tgUserToken) {
      homeStore.fetchUserTradeGetWalletListData()
    }
  }, [homeStore.chainSelect, homeStore.tgUserToken])

  /* 下单和挂单 */
  const tradeStatusTips = (tradeStatus, tnxHash, isLimitTrade) => {
    const status = parseInt(tradeStatus)
    let message = ''
    let messageStatusText = ''
    switch (status) {
      case 1:
        message = 'Order Placed Successfully'
        messageStatusText = 'success'
        break;
      case 4:
        message = 'Order Failed'
        messageStatusText = 'default'
        break;

      default:
        break;
    };

    // 如果是 1 或 4，则移除该 tnxHash
    if (status === 1 || status === 4) {
      // 使用 notistack 提示信息
      enqueueSnackbar(message, { variant: messageStatusText });
      
      if (!isLimitTrade) {
        homeStore.handleRemoveTradeStateListData(tnxHash);
      } else {
        homeStore.handleRemoveLimitTradeStateListData(tnxHash);
      }
    }
  }

  // 定义一个状态来保存定时器ID，用于清除定时器
  const [tradeStateIntervalId, setTradeStateIntervalId] = useState(null);

  /* 获取普通交易状态请求 */
  const fetchUserTradegetTradeState = async (tnxHash) => {
    try {
      const res = await request('lionx/user/trade/getTradeState', {
        chainName: homeStore.chainSelect.requestParams,
        token: homeStore.tgUserToken,
        tnxHash,
      })

      const data = res
      if (data.status === 200) {
        const status = data.data
        tradeStatusTips(status, tnxHash, false)
      } else {
      }
    } catch (error) {
    } finally {
    }
  }

  // 设置定时器函数
  const startTradeStateFetching = () => {
    const id = setInterval(() => {
      // console.log('startTradeStateFetching Fetching trade states...');
      homeStore.tradeStateList.forEach(item => {
        if (item.tnxHash) {
          fetchUserTradegetTradeState(item.tnxHash);
        }
      });
    }, 2000); // 每隔 1 秒请求一次
    setTradeStateIntervalId(id); // 保存定时器ID
  };

  // 清除定时器
  const stopTradeStateFetching = () => {
    if (tradeStateIntervalId) {
      clearInterval(tradeStateIntervalId); // 清除定时器
      setTradeStateIntervalId(null); // 清除定时器ID
    }
  };

  // 定义一个状态来保存定时器ID，用于清除定时器
  const [limitTradeStateIntervalId, setLimitTradeStateIntervalId] = useState(null);

  /* 获取挂单交易状态请求 */
  const fetchUserLimitTradegetLimitState = async (tnxHash) => {
    try {
      const res = await request('lionx/user/trade/getLimitStatus', {
        chainName: homeStore.chainSelect.requestParams,
        token: homeStore.tgUserToken,
        tnxHash,
        walletAddr: homeStore.tgUserInfo.walletAddress
      })

      const data = res
      if (data.status === 200) {
        const status = data.data
        tradeStatusTips(status, tnxHash, true)
      } else if (data.status === 201 && data.message === 'not found trade order') {
        // 使用 notistack 提示信息
        enqueueSnackbar(data.message, { variant: 'default' });

        homeStore.handleRemoveLimitTradeStateListData(tnxHash);
      }
    } catch (error) {
    } finally {
    }
  }

  // 设置定时器函数
  const startLimitTradeStateFetching = () => {
    const id = setInterval(() => {
      // console.log('startLimitTradeStateFetching Fetching trade states...');
      homeStore.limitTradeStateList.forEach(item => {
        if (item.tnxHash && item.walletAddr === homeStore.tgUserInfo.walletAddress) {
          fetchUserLimitTradegetLimitState(item.tnxHash);
        }
      });
    }, 2000); // 每隔 1 秒请求一次
    setLimitTradeStateIntervalId(id); // 保存定时器ID
  };

  // 清除定时器
  const stopLimitTradeStateFetching = () => {
    if (limitTradeStateIntervalId) {
      clearInterval(limitTradeStateIntervalId); // 清除定时器
      setLimitTradeStateIntervalId(null); // 清除定时器ID
    }
  };
  
  useEffect(() => {
    if (homeStore.tgUserToken) {
      if (homeStore.tradeStateList && homeStore.tradeStateList.length === 0) {
        stopTradeStateFetching(); // 组件卸载时清除定时器
      } else {
        startTradeStateFetching(); // 启动定时器
      }

      if (homeStore.tradeStateList && homeStore.tradeStateList.length === 0) {
        startLimitTradeStateFetching(); // 组件卸载时清除定时器
      } else {
        startLimitTradeStateFetching(); // 启动定时器
      }
  
      return () => {
        stopTradeStateFetching(); // 组件卸载时清除定时器
        stopLimitTradeStateFetching()
      };
    }
  }, [homeStore.tradeStateList, homeStore.tgUserToken])

  /* 搜索弹窗 */
  const [openSearchModal, setOpenSearchModal] = useState(false);
  // 打开 Modal
  const handleSearchModalOpen = () => {
    setOpenSearchModal(true);
  };
  // 关闭 Modal 返回上一个页面
  const handleSearchModalClose = () => {
    setOpenSearchModal(false);
  };
  
  /* Tg登录弹窗 */
  const [openTgLoginModal, setOpenTgLoginModal] = useState(false);
  // 打开 Modal
  const handleTgLoginModalOpen = () => {
    setOpenTgLoginModal(true);
  };
  // 关闭 Modal 返回上一个页面
  const handleTgLoginModalClose = () => {
    setOpenTgLoginModal(false);
  };

  /* 钱包管理弹窗 */
  useEffect(() => {
    let userTradeSyncWalletIntervalId;

    // 如果 isOpenTransferFundsModal 为 true，立即调用一次 fetchUserTradeSyncWallet
    if (isOpenTransferFundsModal) {
      fetchUserTradeSyncWallet(); // 立即调用一次

      // 设置每 2 秒调用一次
      userTradeSyncWalletIntervalId = setInterval(() => {
        fetchUserTradeSyncWallet();
      }, 2000);
    } else {
      // 如果 isOpenTransferFundsModal 为 false，清除定时器
      if (userTradeSyncWalletIntervalId) {
        clearInterval(userTradeSyncWalletIntervalId);
      }
    }

    // 清理副作用：当组件卸载或 isOpenTransferFundsModal 变更时清除定时器
    return () => {
      if (userTradeSyncWalletIntervalId) {
        clearInterval(userTradeSyncWalletIntervalId);
      }
    };
  }, [isOpenTransferFundsModal, fetchUserTradeSyncWallet]);
  
  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline /> {/* 全局样式初始化 */}
      
      {/* 根据当前的主题来更新 body 的样式 */}
      <Global
        styles={css`
          html {
            scrollbar-color: ${homeStore.isDarkMode ? '#4d4d4d #28282d' : '#cccccc #f9f9f9'};
          }
          body {
            /* background-color: ${currentTheme.palette.background.default}; */
            /* color: ${currentTheme.palette.text.primary}; */
            /* transition: background-color 0.3s ease, color 0.3s ease; */
          }
        `}
      />

      <div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100vh;
            max-height: 100dvh;
            overflow-y: hidden;
            position: fixed;
            width: 100vw;
            top: 0px;
          `}
        >
          <HomeTopMenuPage
            isDarkMode={homeStore.isDarkMode}
            changeThemeClick={homeStore.handleChangeThemeClick}
            handleSearchModalOpen={handleSearchModalOpen}
            handleTgLoginModalOpen={handleTgLoginModalOpen}
          />

          <div css={css`
            flex: 1;
            height: 100%;
            overflow-y: auto;
            position: relative;
            display: flex;
            flex-direction: column;
          `}>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                {/* 默认子路由，访问 '/' 时渲染 Table 组件 */}
                <Route index element={<HomeMainPage />} /> {/* 默认显示 Table */}
                {/* 在 Home 中定义嵌套的子路由 */}
                <Route path="deal/:cryptoTokenAddress" element={<DealPage />} />
                <Route path="tglogin" element={<TgLoginPage handleTgLoginModalClose={handleTgLoginModalClose} handleTgLoginModalOpen={handleTgLoginModalOpen} />} />
                <Route path="watchlist" element={<WatchlistPage />} />
                <Route path="referral" element={<ReferralPage />} />
                <Route path="address/:cryptoTokenAddress" element={<AddressPage />} />

                <Route path="*" element={<h3>Page Not Found</h3>} />
              </Routes>
            </Suspense>
          </div>

          {/* 搜索弹窗 */}
          {
            openSearchModal && (
              <Suspense>
                <SearchPage openSearchModal={openSearchModal} handleSearchModalClose={handleSearchModalClose} />
              </Suspense>
            )
          }
          
          {/* 登录弹窗 */}
          {
            openTgLoginModal && (
              <Suspense>
                <TgLoginPopup openTgLoginModal={openTgLoginModal} handleTgLoginModalClose={handleTgLoginModalClose} />
              </Suspense>
            )
          }
          
          {/* 资金管理弹窗 */}
          {
            isOpenTransferFundsModal && (
              <Suspense>
                <WalletManagerModal />
              </Suspense>
            )
          }
        </div>
      </div>

    </ThemeProvider>
  );
}

export default observer(App);
