
// 对字符串进行截取并添加省略号
export function formatStringWithEllipsis(inputString = '', startLength = 5, endLength = 4) {
  // 获取字符串的前缀部分
  const start = inputString.slice(0, startLength);

  // 获取字符串的后缀部分
  const end = inputString.slice(-endLength);

  // 组合前后部分，中间插入省略号
  return `${start}...${end}`;
}

// 将字符串的首字母转为大写
export function capitalizeFirstLetter(str) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}
