import axios from 'axios';
import { store } from '@/store'

console.log('process.env', process.env)
// 创建 axios 实例
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // 默认的基础 URL
  timeout: 50000,  // 设置请求超时（可选）
});

// 通用请求函数
export const request = async (url, params = {}, method = 'get', cancelToken = null) => {
  const token = store.homeStore.tgUserToken
  // 创建一个新的 params 对象，避免直接修改传入的 params
  const finalParams = { ...params };

  // 如果 token 存在，将其添加到 finalParams 中
  if (token) {
    finalParams.token = token;
  }

  try {
    const response = await axiosInstance({
      url,
      method,
      params: finalParams,  // 对于 GET 请求，params 会自动附加到 URL 后
      cancelToken,
    });
    const {data} = response
    if (data.status === 302) {
      store.homeStore.handleDisconnectTgUserData()
      return
    }
    return response.data; // 返回响应数据
  } catch (error) {
    // 这里可以统一处理错误
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
    } else {
      console.error(`Error in request to ${url}:`, error);
    }
    throw error; // 可以根据需要继续抛出或处理错误
  }
};

export default request
