import Decimal from 'decimal.js'

/**
 * 格式化科学计数法表示的数字为普通小数
 * @param {string | number} number - 需要格式化的数字，可以是数字或字符串形式
 * @returns {string} - 格式化后的普通小数字符串
 */
export function formatScientificNotationToString(number) {
  if (number === null || number === undefined) return ''
  const decimal = new Decimal(number); // 创建 Decimal 对象

  // 获取数字的字符串表示
  const decimalStr = decimal.toString();

  // 如果数字不包含科学计数法（即不包含 'e'），直接返回
  if (!decimalStr.includes('e')) {
    return decimalStr;
  }

  // 如果是科学计数法，提取整数部分和指数部分
  const [integerPart, exponentPart] = decimalStr.split('e');
  const decimalPlaces = (integerPart.split('.')[1] || '').length; // 小数部分的位数，默认为 0

  // 计算精度：小数部分的位数加上指数部分的绝对值，确保至少保留 18 位小数
  const precision = Math.max(decimalPlaces + Math.abs(parseInt(exponentPart, 10)), 18);

  // 返回格式化后的普通小数
  return decimal.toFixed(precision);
}

// 统计小数点后的连续零
export function countLeadingZerosAfterDecimal(num) {
	// 将数字转换为字符串，并移除不必要的前导零
  const numStr = formatScientificNotationToString(num)
	
	// 找到小数点的位置
	let decimalIndex = numStr.indexOf('.');
	
	// 如果没有小数点或小数部分为空，直接返回 0
	if (decimalIndex === -1 || numStr.slice(decimalIndex + 1).length === 0) {
		return 0;
	}
	
	// 提取小数点后的部分
	let decimalPart = numStr.slice(decimalIndex + 1);
	
	// 使用正则表达式匹配小数点后连续零的数量
	let match = decimalPart.match(/^0+/);  // 匹配以零开头的连续零
	return match ? match[0].length : 0;  // 返回零的数量，如果没有零则返回 0
}

// 获取小数点后4位数字并提取出来
export function extractSignificantDigitsAfterDecimal(num) {
  // 将数字转换为字符串，去除小数点
  const numStr = formatScientificNotationToString(num)

  // 去除小数点
  let decimalPart = numStr.split('.')[1] || ''; // 获取小数部分，若没有小数点，返回空字符串

  // 找到第一个非零数字的位置
  let firstNonZeroIndex = decimalPart.search(/[1-9]/);

  // 如果没有找到非零数字，返回 0
  if (firstNonZeroIndex === -1) {
    return 0;
  }

  // 从第一个非零数字开始截取接下来的四位数字
  let result = decimalPart.slice(firstNonZeroIndex, firstNonZeroIndex + 4);

  // 返回结果，转为数字
  return parseInt(result, 10);
}


// 获取小数点后4位数字并提取出来转成String
export function formatPriceWithPrecision(price) {
  // 检查输入是否为有效数字
  if (isNaN(price) || price === null || price === '' || price === 0) {
    return '0'; // 返回默认值
  }

  const decimalValue = new Decimal(price);
  
  if (decimalValue.gt(1)) {
    // 对于大于或等于1的数字，保留两位小数
    const resStr = decimalValue.toFixed(2).toString();
    const resNum = parseFloat(decimalValue.toFixed(4));
    return [resStr, resNum]
  } else {
    // 小于 1，保留第一个非 0 数字后的四位小数
    const strValue = decimalValue.toString();
    const match = strValue.match(/0\.0*([1-9]\d*)/);
    if (match) {
      // 计算需要保留的小数位数
      const leadingZeros = match[0].length - match[1].length - 2; // 小数点后的连续 0 的数量
      const decimalPlaces = leadingZeros + 4; // 保留第一个非 0 数字后的四位小数
      const res = decimalValue.toFixed(decimalPlaces)
      const resStr = res.toString();
      const resNum = parseFloat(res);
      return [resStr, resNum]
    }
  }
}

// 提取第一个非零数字后四位数字，并保留小数点后的完整结果
export function getFourDigitsAfterFirstNonZero(num) {
  // 将数字转换为字符串，避免科学计数法
  const numStr = formatScientificNotationToString(num)

  // 找到小数点的位置
  let decimalIndex = numStr.indexOf('.');

  // 如果没有小数点，直接返回 0
  if (decimalIndex === -1) return 0;

  // 获取小数部分
  let decimalPart = numStr.slice(decimalIndex + 1);

  // 找到第一个非零数字的位置
  let firstNonZeroIndex = decimalPart.search(/[1-9]/);

  // 如果没有找到非零数字，返回 0
  if (firstNonZeroIndex === -1) {
    return 0;
  }

  // 从第一个非零数字开始截取接下来的四位数字
  let result = decimalPart.slice(firstNonZeroIndex, firstNonZeroIndex + 4);

  // 生成完整的小数结果
  let finalResult = '0.' + decimalPart.slice(0, firstNonZeroIndex) + result;

  return parseFloat(finalResult); // 转换为浮动数，去掉不必要的多余零
}


// 根据数字的位数进行转换
export function formatNumberBySize(num) {
  const decimalNum = new Decimal(num); // 使用 Decimal.js 创建高精度数字
	if (decimalNum.gte(1e9)) {
		// 大于等于 10 亿（B）
		return decimalNum.div(1e9).toFixed(2) + 'B'; // 保留两位小数并加上 "B"
	} else if (decimalNum.gte(1e6)) {
		// 大于等于 100 万（M）
		return decimalNum.div(1e6).toFixed(1) + 'M'; // 保留一位小数并加上 "M"
	} else if (decimalNum.gte(1e4)) {
		// 大于等于 1000（K）
		return decimalNum.div(1e3).toFixed(0) + 'K'; // 不保留小数并加上 "K"
	} else if (decimalNum.lessThan(1)) {
		return decimalNum.toFixed(2, Decimal.ROUND_DOWN);
  } else {
    
    // 使用 Intl.NumberFormat 来格式化为整数并加上千位分隔符  toLocaleString('en-US')
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0, // 确保不保留小数
    });
    return formatter.format(decimalNum.toFixed(0));
		// 小于 1000 的数字直接返回
		// return decimalNum.toString();
		// return decimalNum.toFixed(0);
	}
}


// 根据数字的位数进行转换
export function formatNumberUSDBySize(num) {
  const decimalNum = new Decimal(num); // 使用 Decimal.js 创建高精度数字
	if (decimalNum.gte(1e9)) {
		// 大于等于 10 亿（B）
		const decimalValue = decimalNum.div(1e9).toFixed(2) // 保留两位小数并加上 "B"
    const formattedValue = decimalValue.replace(/\.?0+$/, '');
    return formattedValue + 'B';
	} else if (decimalNum.gte(1e6)) {
		// 大于等于 100 万（M）
		const decimalValue = decimalNum.div(1e6).toFixed(1) // 保留一位小数并加上 "M"
    const formattedValue = decimalValue.replace(/\.?0+$/, '');
    return formattedValue + 'M';
	} else if (decimalNum.gte(1e4)) {
		// 大于等于 1000（K）
		const decimalValue = decimalNum.div(1e3).toFixed(1) // 不保留小数并加上 "K"
    const formattedValue = decimalValue.replace(/\.?0+$/, '');
    return formattedValue + 'K';
	} else if (decimalNum.lte(0.01) && decimalNum.gt(0)) {
    return '0.01'
  } else if (decimalNum.lessThan(1)) {
		const decimalValue = decimalNum.toFixed(2, Decimal.ROUND_DOWN);
    const formattedValue = decimalValue.replace(/\.?0+$/, '');
    return formattedValue
  } else {

    const decimalValue = decimalNum.toFixed(2, Decimal.ROUND_DOWN);
    // 如果是零，返回 '0'，否则去掉末尾的0
    if (decimalValue === '0.00') {
      return '0';
    }
    // 去掉末尾的0
    const formattedValue = decimalValue.replace(/\.?0+$/, '');
    
    return formattedValue;
	}
}

/* 据数字的大小来格式化百分比 */
export function formatPercentageBySize(num = 0) {
  // 如果 num 是空值或无效值（null、undefined、NaN 或空字符串），返回默认值（例如：返回 "0"）
  if (num === undefined || isNaN(num) || num === '') {
    return '0';
  }
  const decimalNum = new Decimal(num); // 使用 Decimal.js 创建高精度数字

  // 检查数字是否超过百万
  if (decimalNum.abs().greaterThanOrEqualTo(1000000)) {
    const result = decimalNum.dividedBy(1000000).toFixed(0);
    return `${result}M`;
  }

  if (decimalNum.abs().greaterThanOrEqualTo(100000)) {
    const result = decimalNum.dividedBy(1000).toFixed(0);
    return `${result}K`;
  }

  if (decimalNum.abs().greaterThanOrEqualTo(100)) {
    // 使用 Intl.NumberFormat 来格式化为整数并加上千位分隔符  toLocaleString('en-US')
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0, // 确保不保留小数
    });
    return formatter.format(decimalNum.toString());
  }

  if (decimalNum.abs().lessThan(100)) {
    // 小于100时保留两位小数
    return decimalNum.toFixed(2);
  }

  return decimalNum.toString();
}

// 数字格式化为带千分位分隔符
export function formatNumberWithThousandSeparators(number) {
  // 使用 decimal.js 保留两位小数
  const decimalNumber = new Decimal(number).toFixed(2);

  // 格式化为带千分位分隔符的数字
  return parseFloat(decimalNumber).toLocaleString('en-US');
}


/**
 * 执行除法并四舍五入到最近的整数
 * @param {number} numerator - 被除数
 * @param {number} denominator - 除数
 * @param {number} isPercent - 除数是否是百分比
 * @returns {number} - 除法结果，四舍五入到整数
 */
export function divideAndRound(numerator = 0, denominator = 1, isPercent = false) {
  // 如果 denominator 为 null 或 undefined，设置默认值 1
  if (denominator === null || denominator === undefined) {
    return 0
  }
  // 检查 denominator 是否为有效数字
  if (denominator === 0) {
    return 0
  }
  // 创建 Decimal 实例
  const num = new Decimal(numerator);
  let denom = new Decimal(denominator);

  if (isPercent) {
    denom = denom.div(100)
  }

  // 执行除法并四舍五入到最近的整数
  const result = num.div(denom) // .toDecimalPlaces(0, Decimal.ROUND_HALF_UP);

  // 返回最终结果
  return result.toString();  // 使用 toNumber() 转换为普通数字
}

/**
 * 执行乘法运算，并根据百分比参数处理，结果四舍五入到最近整数。
 * @param {number | string} multiplier1 - 第一个乘数（数字或字符串）。
 * @param {number | string} multiplier2 - 第二个乘数（数字或字符串）。如果是百分比，需以小数形式传入。
 * @param {boolean} isPercentage - 第二个乘数是否为百分比（例如 0.20 表示 20%）。
 * @returns {number} 乘法结果，四舍五入到最近整数。
 */
export function multiplyAndRound(multiplier1 = 0, multiplier2 = 0, isPercentage) {
  // 创建 Decimal 对象，确保数值的精确度
  let num1 = new Decimal(multiplier1);
  let num2 = new Decimal(multiplier2);

  // 如果第二个乘数是百分比，将其转换为小数
  if (isPercentage) {
    num2 = num2.div(100);  // 百分比转化为小数
  }

  // 执行乘法
  const result = num1.times(num2);

  // 四舍五入到最近整数并返回
  // return result.toDecimalPlaces(0, Decimal.ROUND_HALF_UP).toNumber();
  return result.toString();
}

/**
 * 执行高精度乘法运算，支持默认值和参数校验
 * @param {number|string} num1 - 第一个乘数，默认为 0
 * @param {number|string} num2 - 第二个乘数，默认为 0
 * @returns {string} - 返回乘法结果，字符串格式
 */
// export function multiply(num1 = 0, num2 = 0) {
//   // 检查 num1 和 num2 是否为有效数字
//   if (isNaN(num1) || isNaN(num2)) {
//     return '0'
//   }

//   // 创建两个 Decimal 对象，确保输入的值能被正确解析
//   const decimal1 = new Decimal(num1);
//   const decimal2 = new Decimal(num2);

//   // 执行乘法运算
//   const result = decimal1.mul(decimal2);

//   // 返回结果的字符串表示，以避免精度丢失
//   return result.toString();
// }

/**
 * 执行高精度乘法运算，支持默认值和参数校验
 * @param {number|string} num1 - 第一个乘数，默认为 0
 * @param {number|string} num2 - 第二个乘数，默认为 0
 * @param {number} [decimalPlaces] - 小数位数，如果提供则按该小数位返回结果
 * @returns {string} - 返回乘法结果，字符串格式
 */
export function multiply(num1 = 0, num2 = 0, decimalPlaces) {
  // 检查 num1 和 num2 是否为有效数字
  if (isNaN(num1) || isNaN(num2)) {
    return '0';
  }

  // 创建两个 Decimal 对象，确保输入的值能被正确解析
  const decimal1 = new Decimal(num1);
  const decimal2 = new Decimal(num2);

  // 执行乘法运算
  const result = decimal1.mul(decimal2);

  // 如果提供了 decimalPlaces，返回指定小数位的结果
  if (decimalPlaces !== undefined) {
    return result.toFixed(decimalPlaces);  // 保留指定的小数位
  }

  // 如果没有提供 decimalPlaces，则返回默认的结果（不限制小数位）
  return result.toString();
}

/**
 * 格式化数字，保留指定小数位数，去除尾部零
 * @param {string | number} value - 输入的数字（字符串或数字）
 * @param {number} decimalPlaces - 保留的小数位数（最多保留几位）
 * @returns {string} 格式化后的数字，去掉尾部的零
 */
export function formatNumberPrecision(value, decimalPlaces) {
  // 使用 decimal.js 创建 Decimal 实例，确保数值精确
  const num = new Decimal(value);
  
  // 将数字保留到指定的小数位，使用 Decimal.ROUND_DOWN 来避免四舍五入，保持精度
  let formatted = num.toFixed(decimalPlaces, Decimal.ROUND_DOWN);

  // 去除小数点后不必要的尾零（正则匹配去掉尾部的零）
  formatted = formatted.replace(/\.?0+$/, '');

  return formatted;
}

/**
 * 格式化金额，保留最多两位小数，不进行四舍五入，且当小数部分为0时去掉小数。
 * 
 * @param {number|string|Decimal} amount - 要格式化的数字，可以是数字、字符串或Decimal对象。
 * @returns {string} - 格式化后的金额字符串，最多保留两位小数，若无小数则返回整数。
 */
export function formatAmountWithoutRounding(amount = 0, decimalPlaces = 2) {
  // 创建 Decimal 实例
  const decimalAmount = new Decimal(amount);

  // 使用 ROUND_DOWN 来保留指定的小数位数，避免四舍五入
  let formattedAmount = decimalAmount.toFixed(decimalPlaces, Decimal.ROUND_DOWN);

  // 检查格式化后的金额是否以 ".00"（或类似）结尾，若是则返回整数部分
  if (formattedAmount.endsWith('.' + '0'.repeat(decimalPlaces))) {
      return decimalAmount.toFixed(0);  // 返回整数部分
  }

  // 否则返回保留指定小数位数的金额字符串
  return formattedAmount;
}