
export const chainListData = [
  {
    id: 'solana',
    name: 'Solana',
    requestParams: 'solana',
    imgParams: 'solana',
    homeParams: 'solana',
    homeUrlParams: '/solana',
    shortName: 'SOL',
    fetchName: 'solana',
    chainId: 'solana',
    chainTransactionWebsite: 'https://solscan.io/tx/',
    chainAccountWebsite: 'https://solscan.io/account/',
    chainTokenWebsite: 'https://solscan.io/token/',
    urlName: 'sol',
    socketName: 'sol'
  },
  {
    id: 'eth',
    name: 'Ethereum',
    requestParams: 'eth',
    imgParams: 'ethereum',
    homeParams: 'ethereum',
    homeUrlParams: '/ethereum',
    shortName: 'ETH',
    fetchName: 'eth',
    chainId: 'ethereum',
    chainTransactionWebsite: 'https://etherscan.io/tx/',
    chainAccountWebsite: 'https://etherscan.io/address/',
    chainTokenWebsite: 'https://etherscan.io/token/tokenholderchart/',
    urlName: 'eth',
    socketName: 'eth'
  }
]