import dayjs from 'dayjs';  // 引入 dayjs 库
import relativeTime from 'dayjs/plugin/relativeTime';  // 引入相对时间插件
import duration from 'dayjs/plugin/duration';  // 引入相对时间插件

// 使用相对时间插件
dayjs.extend(relativeTime);
dayjs.extend(duration);

// 时间格式化为"15d 17h ago"
export function formatTimeAgo(timestamp) {
  const now = dayjs(); // 当前时间
  const diff = now.diff(dayjs(timestamp)); // 计算时间差（毫秒）

  const durationObj = dayjs.duration(diff); // 使用 dayjs.duration 获取时间差的持续时间

  const years = durationObj.years(); // 获取年数
  const months = durationObj.months(); // 获取月数
  let days = durationObj.days(); // 获取天数
  let hours = durationObj.hours(); // 获取小时数
  let minutes = durationObj.minutes(); // 获取分钟数

  let result = '';

  // 超过一年
  if (years > 0) {
    result += `${years}y ${months}mo ${days}d`;
    return result;
  }

  // 超过一个月
  if (months > 0) {
    result += `${months}mo ${days}d`;
    return result;
  }

  // 超过一天：天数和小时分开计算
  if (days > 0) {
    result += `${days}d ${hours}h`;
    return result;
  }

  // 超过一小时：小时数
  if (hours > 0) {
    result += `${hours}h ${minutes}m`;
    return result;
  }

  // 超过一分钟：分钟数
  if (minutes > 0) {
    result += `${minutes}m`;
    return result;
  }

  // 如果时间差非常小，展示秒数
  result += Math.floor(diff / 1000) + 's'; // 秒数

  // 返回最终的格式化结果
  return result; // 去掉尾部多余空格
}

// 函数：根据输入字符串动态减去时间
export function subtractSearchTime(timeString) {
  const match = timeString.match(/^([a-zA-Z]+)(\d+)$/);
  
  if (match) {
    const unit = match[1]; // 获取单位（例如 'm'、'h'）
    const value = parseInt(match[2], 10); // 获取数值（例如 '5'、'1'）

    // 根据单位选择时间单位
    let unitMap = {
      'm': 'minute',
      'h': 'hour',
      'd': 'day',
      'M': 'month',
      'y': 'year',
    };

    if (unitMap[unit]) {
      // 减去相应的时间
      return dayjs().subtract(value, unitMap[unit]);
    } else {
      throw new Error('Invalid time unit');
    }
  } else {
    throw new Error('Invalid time string format');
  }
}

/**
 * 计算并格式化时间差
 * @param {string} time - 传入的时间字符串
 * @returns {string} - 返回格式化后的时间差字符串（如：'30s ago', '2h 15m ago'）
 */
export const getTimeAgo = (time) => {
  const diffInSeconds = dayjs().diff(dayjs(time), 'second');  // 获取时间差（秒）

  if (diffInSeconds < 60) {
    // 小于1分钟，返回秒数
    return `${diffInSeconds}s`;
  } else if (diffInSeconds < 3600) {
    // 大于1分钟且小于1小时，返回分钟数
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return `${diffInMinutes}m`;
  } else {
    // 大于1小时，返回小时和分钟（如果有分钟）
    const diffInHours = Math.floor(diffInSeconds / 3600);
    const diffInMinutes = Math.floor((diffInSeconds % 3600) / 60);

    if (diffInMinutes === 0) {
      // 如果分钟数为0，只显示小时
      return `${diffInHours}h`;
    } else {
      // 显示小时和分钟
      return `${diffInHours}h ${diffInMinutes}m`;
    }
  }
};
