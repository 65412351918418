import styled from '@emotion/styled'

import { Link } from 'react-router-dom'
import { Button, Menu, Switch  } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


export const PageWrap = styled.div`
`
export const PageMain = styled.main`
  /* display: block; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100dvh;
  overflow-y: hidden;
  position: fixed;
  width: 100vw;
  top: 0px;
`
export const PagePCHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding-inline: 16px;
  z-index: 111;
  align-items: center;

  /* position: sticky;
  top: -1px;
  left: 0; */
  flex-shrink: 0;
  z-index: 1300;
  position: initial;
  width: 100vw;
  /* height: auto; */
  /* background: #111116; */
  display: flex;
  /* flex-direction: column; */
  overflow: hidden;
  /* border-right-width: 1px;
  border-right-color: #28282d; */

  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? 'rgb(244, 244, 245)' : '#0d0d0d';
  }};
`

export const PagePCHeaderLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: unset;
`
export const PagePCHeaderLeftLogo = styled.a`
  color: rgb(0, 0, 0);
  cursor: pointer;
  /* height: 32px; */

  height: 56px;
  display: flex;
  min-width: 0px;
  align-items: center;
  background: #0d0d0d;
  @media screen and (min-width: 62em) {
    min-width: 100px;
  }
`
export const PagePCHeaderLeftLogoImg = styled.img`
  /* width: 118px; */
  /* height: 32px; */
  /* height: 56px; */
  height: 42px;
`
export const PagePCHeaderLeftList = styled.div`
  display: flex;
  margin-left: 4px;
  align-items: center;
`
export const PagePCHeaderLeftListItem = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  margin-left: 12px;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#6E727D' : '#9AA0AA';
  }};
  font-weight: 500;
  height: 100%;
`

export const PagePCHeaderMiddle = styled.div`
  flex: 1 1 0%;

  display: flex;
  padding-inline: 24px;
  flex-direction: column;
  position: relative;
  max-width: 500px;
  justify-content: center;
`
export const PagePCHeaderMiddleContent = styled.div`
  display: flex;
  width: 100%;
`
export const PagePCHeaderMiddleInputWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  isolation: isolate;
  /* --input-font-size: var(--chakra-fontSizes-sm);
  --input-padding: var(--chakra-space-3);
  --input-border-radius: var(--chakra-radii-sm);
  --input-height: var(--chakra-sizes-8); */
  height: 40px;
  font-size: 14px;

  cursor: pointer;
`
export const PagePCHeaderMiddleInputSearchIconWrap = styled.div`
  left: 0px;
  width: 32px;
  height: 40px;
  font-size: 14px;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#AEB2BD' : '#5C6068';
  }};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  z-index: 2;
  padding-left: 12px;
  padding-right: 4px;
`
export const PagePCHeaderMiddleInputSearchIcon = styled(SearchIcon)`
  width: 16px;
  height: 20px;
`
export const PagePCHeaderMiddleInputContent = styled.div`
  width: 100%;
  height: 40px;
  padding-inline-start: 32px;
  padding-inline-end: 32px;
  min-width: 0px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  /* transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  --input-font-size: var(--chakra-fontSizes-sm);
  --input-padding: var(--chakra-space-3);
  --input-border-radius: var(--chakra-radii-sm);
  --input-height: var(--chakra-sizes-8); */
  font-size: 14px;
  padding-right: 32px;
  padding-left: 32px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0);
  /* color: #292C33; */
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};

  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#718096' : 'rgba(255, 255, 255, 0.24)';
  }};
  line-height: 40px;
  white-space: nowrap;

  /* &:focus, &[data-focus] {
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #292C33;
  }
  &:focus-visible, &[data-focus-visible] {
    z-index: 1;
    border-color: #292C33;
    box-shadow: none;
  }
  :focus-visible {
    outline: none;
    box-shadow: none !important;
    border-color: rgba(255, 255, 255, 0);
  }
  &::placeholder {
    color: #718096;
  } */
`
export const PagePCHeaderMiddleInputTip = styled.div`
  right: 0px;
  width: 32px;
  height: 40px;
  font-size: 14px;
  color: #292C33;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  z-index: 2;
  padding-right: 12px;
`
export const PagePCHeaderMiddleInputTipContent = styled.div`
  display: flex;
  height: 20px;
  background: #E4E4E4;
  border-radius: 4px;
  justify-content: center;
  color: #AEB2BD;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
`

export const PagePCHeaderRight = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 4px;
`
export const PagePCHeaderRightChainSelectWrap = styled.div`
  display: flex;
  white-space: nowrap;
  height: 40px;
  border-radius: 12px;
  align-items: center;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
  min-width: 120px;
  cursor: pointer;
`
export const PagePCHeaderRightChainSelectContainer = styled.span`
  pointer-events: none;
  flex: 1 1 auto;
  min-width: 0px;
`
export const PagePCHeaderRightChainSelectContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;
  min-width: 120px;
`
export const PagePCHeaderRightChainSelectLeft = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  gap: 4px;

  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? 'rgb(41, 44, 51)' : 'rgb(245, 245, 245)';
  }};
`
export const PagePCHeaderRightChainSelectLeftImg = styled.img`
  width: 20px;
  height: 20px;
`
export const PagePCHeaderRightChainSelectIconWrap = styled.div`
  display: flex;
`
export const PagePCHeaderRightChainSelectKeyboardArrowDownIcon= styled(KeyboardArrowDownIcon)`
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  vertical-align: middle;
  width: 20px;
  height: 20px;
`

export const PagePCHeaderRightChainSelectPopupWrap = styled(Menu)`
  /* margin-top: 8px !important; */
`
export const PagePCHeaderRightChainSelectPopupList = styled.div`
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* --menu-bg: #fff;
  --menu-shadow: var(--chakra-shadows-sm); */
  color: inherit;
  z-index: 1;
  border-radius: 12px;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#26282C';
  }};
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px; */
  border: 0;
  padding-inline: 0.5rem;
  padding: 8px;
  min-width: 120px;
`
export const PagePCHeaderRightChainSelectPopupListItem = styled.div`
  text-decoration: none;
  color: inherit;
  user-select: none;
  display: flex;
  width: 100%;
  text-align: start;
  flex: 0 0 auto;
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* transition-property: var(--chakra-transition-property-background);
  transition-duration: var(--chakra-transition-duration-ultra-fast);
  transition-timing-function: var(--chakra-transition-easing-ease-in); */
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#26282C';
  }};
  padding: 0px;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding-inline: 8px;
  gap: 4px;
  border-radius: 8px;
  height: 32px;
  cursor: pointer;
  margin-top: 4px;
  min-width: 104px;
  
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? 'rgb(41, 44, 51)' : '#F5F5F5';
  }};
  &:hover, &[data-hover] {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
  &:first-of-type{
    margin-top: 0;
  }
  &.nav-link-active {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
`
export const PagePCHeaderRightChainSelectPopupListItemImg = styled.img`
  width: 20px;
  height: 20px;
`

export const PagePCHeaderRightSettingBtn = styled(Button)`
  display: inline-flex;
  appearance: none;
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal); */
  align-self: center;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  padding: 0px;
  width: 40px;
  height: 40px;
  background: transparent;

  min-width: 40px;
  color: rgb(41, 44, 51);

  &:hover, &[data-hover] {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
    }};
  }
`
export const PagePCHeaderRightSettingBtnContent = styled.span`
  pointer-events: none;
  flex: 1 1 auto;
  min-width: 0px;
`
export const PagePCHeaderRightSettingBtnIconWrap = styled.span`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  justify-content: center;

  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
`
export const PagePCHeaderRightSettingsOutlinedIcon = styled(SettingsOutlinedIcon)`
`

export const PagePCHeaderRightSettingBtnPopupList = styled.div`
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* --menu-bg: #fff;
  --menu-shadow: var(--chakra-shadows-sm); */
  color: inherit;
  min-width: 224px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px; */
  border: 0;
  font-size: 12px;
  display: block;
  width: auto;
  border-radius: 12px;
  padding-inline: 0px;
  z-index: 1001;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
`
export const PagePCHeaderRightSettingBtnPopupListItem = styled.div`
  text-decoration: none;
  color: inherit;
  user-select: none;
  display: flex;
  width: 100%;
  text-align: start;
  flex: 0 0 auto;
  outline: transparent solid 2px;
  outline-offset: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  /* transition-property: var(--chakra-transition-property-background);
  transition-duration: var(--chakra-transition-duration-ultra-fast);
  transition-timing-function: var(--chakra-transition-easing-ease-in); */
  height: 35px;
  -webkit-box-align: center;
  align-items: center;
  background: transparent;
  border-radius: 0px;
  padding-inline: 16px;

  cursor: pointer;
  &:hover, &[data-hover] {
    background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#E4E4E4' : '#26282C';
  }};
  }
`
export const PagePCHeaderRightSettingBtnPopupListItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`
export const PagePCHeaderRightSettingBtnPopupNotifyIconWrap = styled.div`
  display: flex;
  transform: rotate(-90deg);
`
export const PagePCHeaderRightSettingBtnPopupNotifyKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  width: 18px;
  height: 18px;
`
export const PagePCHeaderRightSettingBtnPopupThemeSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: #FFFFFF;
    /* &:hover {
      background-color: green
    } */
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #6c9fea;
    opacity: 1;
  }
`

export const PagePCHeaderRightConnectStatusWrap = styled.div`
  display: flex;
  align-items: center;
`
export const PagePCHeaderRightConnectStatusContainer = styled.div`
`
export const PagePCHeaderRightLinkWalletConectContent = styled.div`

`
export const PagePCHeaderRightDisconnectContent = styled.div`
`
export const PagePCHeaderRightConnectContent = styled.div`
  display: flex;
  width: 164px;
  justify-content: right;
`
export const PagePCHeaderRightConnectBtn = styled(Button)`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  /* transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal); */
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#E4E4E4' : '#26282C';
  }};
  border-radius: 0.375rem;
  height: 28px;
  font-weight: 600;
  font-size: 12px;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#F4F4F5' : '#111111';
  }};
  min-width: 85px;
  padding-inline: 8px;

  @media screen and (min-width: 62em) {
    padding-inline: 16px;
  }
`

export const PagePCHeaderRightCongregateContent = styled.div`
  display: flex;
  border-radius: 0.75rem;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-inline: 8px;
  align-items: center;
  height: 48px;
  position: relative;
  z-index: 999;
  min-width: 164px;
  justify-content: center;
`
export const PagePCHeaderRightCongregateTgWrap = styled.div`
  display: flex;
  position: unset;
`
export const PagePCHeaderRightCongregateTgIconWrap = styled.div`
  display: flex;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  background-size: cover;
`
export const PagePCHeaderRightCongregateTelegramIcon = styled(TelegramIcon)`
  width: 24px;
  height: 24px;
`
export const PagePCHeaderRightCongregateWalletInfoWrap = styled.div`
  display: flex;
  border-radius: 0.75rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  flex-direction: column;
  margin-left: 8px;
`
export const PagePCHeaderRightCongregateWalletBalanceWrap = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  gap: 0.25rem;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
`
export const PagePCHeaderRightCongregateWalletBalanceDehazeIcon = styled(DehazeIcon)`
  width: 12px;
  height: 12px;
`
export const PagePCHeaderRightCongregateWalletBalanceNum = styled.div`
  display: flex;
`
export const PagePCHeaderRightCongregateWalletAddressWrap = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#6E727D' : '#9AA0AA';
  }};
  font-size: 12px;
`
export const PagePCHeaderRightCongregateWalletAddressContent = styled.div`
  display: flex;
  align-items: center;
`
export const PagePCHeaderRightCongregateWalletAddressContentContentCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
  margin-left: 4px;
  width: 12px;
  height: 12px;
  fill: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? 'rgb(110, 114, 125)' : 'rgb(154, 160, 170)';
  }};
`
export const PagePCHeaderRightCongregateMoreWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`
export const PagePCHeaderRightCongregateMoreIconWrap = styled.div`
  display: flex;
  cursor: pointer;
`
export const PagePCHeaderRightCongregateMoreKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon, {
  shouldForwardProp: (prop) => prop !== 'isRotate',
})`
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  vertical-align: middle;
  width: 20px;
  height: 20px;
  transform: ${props => props.isRotate ? 'rotate(180deg)' : ''};
`

export const PagePCHeaderRightCongregateMorePopupWrap = styled(Menu)`
`
export const PagePCHeaderRightCongregateMorePopupList = styled.div`
  /* position: absolute;
  right: 0px;
  top: 56px; */
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
  width: 100%;
  min-width: 220px;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px; */

  box-sizing: border-box;
`
export const PagePCHeaderRightCongregateMorePopupListLink = styled(Link)`
  display: flex;
  border-radius: 0.5rem;
  font-size: 12px;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  background: transparent;
  padding-inline: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 2rem;
  align-items: center;
  cursor: pointer;

  &:hover, &[data-hover] {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
`
export const PagePCHeaderRightCongregateMorePopupListItem = styled.div`
  display: flex;
  border-radius: 0.5rem;
  font-size: 12px;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  background: transparent;
  padding-inline: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 2rem;
  align-items: center;
  cursor: pointer;

  &:hover, &[data-hover] {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
`




/* 移动端头部 */
export const PageMobileHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const PageMobileHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding-inline: 0.75rem;
  z-index: 111;
  align-items: center;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#0d0d0d';
  }};
`
export const PageMobileHeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
`
export const PageMobileHeaderHomeLink = styled.a`
display: flex;
    min-width: 24px;

`
export const PageMobileHeaderHomeLinkLogo = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-right: 4px;
`
export const PageMobileHeaderMainBody = styled.div`
    display: flex;
    z-index: 222;
    align-items: center;
    flex: 1 1 0%;
    gap: 12px;
    justify-content: right;
`
export const PageMobileHeaderMainChain = styled.div`

display: flex;
    white-space: nowrap;
    height: 32px;
    border-radius: 12px;
    -webkit-box-align: center;
    align-items: center;
    background: #FFFFFF;
    min-width: 100px;
    cursor: pointer;
`

export const PageMobileHeaderMainChainSelectWrap = styled.div`
  display: flex;
  white-space: nowrap;
  height: 32px;
  border-radius: 12px;
  align-items: center;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
  min-width: 100px;
  cursor: pointer;
`
export const PageMobileHeaderMainChainSelectContainer = styled.span`
  pointer-events: none;
  flex: 1 1 auto;
  min-width: 0px;
`
export const PageMobileHeaderMainChainSelectContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;
  min-width: 120px;
`
export const PageMobileHeaderMainChainSelectLeft = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  gap: 4px;

  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? 'rgb(41, 44, 51)' : 'rgb(245, 245, 245)';
  }};
`
export const PageMobileHeaderMainChainSelectLeftImg = styled.img`
  width: 20px;
  height: 20px;
`
export const PageMobileHeaderMainChainSelectIconWrap = styled.div`
  display: flex;
`
export const PageMobileHeaderMainChainSelectKeyboardArrowDownIcon= styled(KeyboardArrowDownIcon)`
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  vertical-align: middle;
  width: 20px;
  height: 20px;
`

export const PageMobileHeaderMainChainSelectPopupWrap = styled(Menu)`
  /* margin-top: 8px !important; */
`
export const PageMobileHeaderMainChainSelectPopupList = styled.div`
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* --menu-bg: #fff;
  --menu-shadow: var(--chakra-shadows-sm); */
  color: inherit;
  z-index: 1;
  border-radius: 12px;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#26282C';
  }};
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px; */
  border: 0;
  padding-inline: 0.5rem;
  padding: 8px;
  min-width: 120px;
`
export const PageMobileHeaderMainChainSelectPopupListItem = styled.div`
  text-decoration: none;
  color: inherit;
  user-select: none;
  display: flex;
  width: 100%;
  text-align: start;
  flex: 0 0 auto;
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* transition-property: var(--chakra-transition-property-background);
  transition-duration: var(--chakra-transition-duration-ultra-fast);
  transition-timing-function: var(--chakra-transition-easing-ease-in); */
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#26282C';
  }};
  padding: 0px;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  padding-inline: 8px;
  gap: 4px;
  border-radius: 8px;
  height: 32px;
  cursor: pointer;
  margin-top: 4px;
  min-width: 104px;
  
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? 'rgb(41, 44, 51)' : '#F5F5F5';
  }};
  &:hover, &[data-hover] {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
  &:first-of-type {
    margin-top: 0;
  }
  &.nav-link-active {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
`
export const PageMobileHeaderMainChainSelectPopupListItemImg = styled.img`
  width: 20px;
  height: 20px;
`


export const PageMobileHeaderMainSearchIconWrap = styled.div`
display: flex;
    justify-content: center;

`
export const PageMobileHeaderMainSearchIcon = styled(SearchIcon)`
width: 20px;
height: 20px;
`


export const PageMobileHeaderMainSettingBtn = styled(Button)`
  display: inline-flex;
  appearance: none;
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal); */
  align-self: center;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  padding: 0px;
  width: 20px;
  height: 20px;
  background: transparent;

  min-width: 20px;
  color: rgb(41, 44, 51);
`
export const PageMobileHeaderMainSettingBtnContent = styled.span`
  pointer-events: none;
  flex: 1 1 auto;
  min-width: 0px;
`
export const PageMobileHeaderMainSettingBtnIconWrap = styled.span`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  justify-content: center;

  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
`
export const PageMobileHeaderMainSettingsOutlinedIcon = styled(SettingsOutlinedIcon)`
`

export const PageMobileHeaderMainSettingBtnPopupList = styled.div`
  outline: transparent solid 2px;
  outline-offset: 2px;
  /* --menu-bg: #fff;
  --menu-shadow: var(--chakra-shadows-sm); */
  color: inherit;
  min-width: 224px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px; */
  border: 0;
  font-size: 12px;
  display: block;
  width: auto;
  border-radius: 12px;
  padding-inline: 0px;
  z-index: 1001;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
`
export const PageMobileHeaderMainSettingBtnPopupListItem = styled.div`
  text-decoration: none;
  color: inherit;
  user-select: none;
  display: flex;
  width: 100%;
  text-align: start;
  flex: 0 0 auto;
  outline: transparent solid 2px;
  outline-offset: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  /* transition-property: var(--chakra-transition-property-background);
  transition-duration: var(--chakra-transition-duration-ultra-fast);
  transition-timing-function: var(--chakra-transition-easing-ease-in); */
  height: 35px;
  -webkit-box-align: center;
  align-items: center;
  background: transparent;
  border-radius: 0px;
  padding-inline: 16px;

  cursor: pointer;
  &:hover, &[data-hover] {
    background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#E4E4E4' : '#26282C';
  }};
  }
`
export const PageMobileHeaderMainSettingBtnPopupListItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`
export const PageMobileHeaderMainSettingBtnPopupNotifyIconWrap = styled.div`
  display: flex;
  transform: rotate(-90deg);
`
export const PageMobileHeaderMainSettingBtnPopupNotifyKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  width: 18px;
  height: 18px;
`
export const PageMobileHeaderMainSettingBtnPopupThemeSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: #FFFFFF;
    /* &:hover {
      background-color: green
    } */
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #88D693;
    opacity: 1;
  }
`


export const PageMobileHeaderMainConnectStatusWrap = styled.div`
  display: flex;
  align-items: center;
`
export const PageMobileHeaderMainConnectStatusContainer = styled.div`
`
export const PageMobileHeaderMainLinkWalletConectContent = styled.div`

`
export const PageMobileHeaderMainDisconnectContent = styled.div`

`
export const PageMobileHeaderMainConnectContent = styled.a`
  display: flex;
  width: 100px;
  justify-content: right;
`
export const PageMobileHeaderMainConnectBtn = styled(Button)`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  /* transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal); */
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#E4E4E4' : '#26282C';
  }};
  border-radius: 0.375rem;
  height: 28px;
  font-weight: 600;
  font-size: 12px;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#F4F4F5' : '#111111';
  }};
  min-width: 85px;
  padding-inline: 8px;

  @media screen and (min-width: 62em) {
    padding-inline: 16px;
  }
`

export const PageMobileHeaderMainCongregateContent = styled.div`
  display: flex;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 0.75rem;
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-inline: 8px;
  align-items: center;
  height: 36px;
  position: relative;
  z-index: 999;
  min-width: 100px;
  justify-content: center;
`
export const PageMobileHeaderMainCongregateTgWrap = styled.div`
  display: flex;
  position: unset;
`
export const PageMobileHeaderMainCongregateTgIconWrap = styled.div`
  display: flex;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  background-size: cover;
`
export const PageMobileHeaderMainCongregateTelegramIcon = styled(TelegramIcon)`
  width: 24px;
  height: 24px;
`
export const PageMobileHeaderMainCongregateWalletInfoWrap = styled.div`
  display: flex;
  border-radius: 0.75rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  flex-direction: column;
  margin-left: 8px;
`
export const PageMobileHeaderMainCongregateWalletBalanceWrap = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  gap: 0.25rem;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
`
export const PageMobileHeaderMainCongregateWalletBalanceDehazeIcon = styled(DehazeIcon)`
  width: 12px;
  height: 12px;
`
export const PageMobileHeaderMainCongregateWalletBalanceNum = styled.div`
  display: flex;
`
export const PageMobileHeaderMainCongregateMoreWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
`
export const PageMobileHeaderMainCongregateMoreIconWrap = styled.div`
  display: flex;
  cursor: pointer;
`
export const PageMobileHeaderMainCongregateMoreKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon, {
  shouldForwardProp: (prop) => prop !== 'isRotate',
})`
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  vertical-align: middle;
  width: 20px;
  height: 20px;
  transform: ${props => props.isRotate ? 'rotate(180deg)' : ''};
`

export const PageMobileHeaderMainCongregateMorePopupWrap = styled(Menu)`
`
export const PageMobileHeaderMainCongregateMorePopupList = styled.div`
  /* position: absolute;
  right: 0px;
  top: 56px; */
  background: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#FFFFFF' : '#17181B';
  }};
  width: 100%;
  min-width: 220px;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px; */

  box-sizing: border-box;
`
export const PageMobileHeaderMainCongregateMorePopupListLink = styled(Link)`
  display: flex;
  border-radius: 0.5rem;
  font-size: 12px;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  background: transparent;
  padding-inline: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 2rem;
  align-items: center;
  cursor: pointer;

  &:hover, &[data-hover] {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
`
export const PageMobileHeaderMainCongregateMorePopupListItem = styled.div`
  display: flex;
  border-radius: 0.5rem;
  font-size: 12px;
  color: ${(props) => {
    const theme = props.theme;
    return theme.palette.mode === 'light' ? '#292C33' : '#F5F5F5';
  }};
  background: transparent;
  padding-inline: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  min-height: 2rem;
  align-items: center;
  cursor: pointer;

  &:hover, &[data-hover] {
    background: ${(props) => {
      const theme = props.theme;
      return theme.palette.mode === 'light' ? '#DBDEE6' : '#393C43';
    }};
  }
`



export const PageBodyWrap = styled.div`
  /* position: relative; */
  flex: 1;
  height: 100%;
  /* overflow-y: hidden; */
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`
